import { convertToSalesPeopleOptions, formatNumberWithComma, getFormatDate } from '@/utils/common';
import { Button, Modal, Select, Table, TableColumnsType, message } from 'antd';
import React, { useEffect, useState } from 'react';

import { deleteProduct, patchProductsDetail } from '@/api/public';
import BasicPopup from '@/components/Common/BasicPopup';
import ExcelDownloadButton from '@/components/Common/Button/ExcelDownloadButton';
import SortingButton from '@/components/Common/SortingButton';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { productsEnumAtom } from '@/store/products';
import { ExclamationCircleFilled } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const AssuranceSearchResult = ({
  selectedFilterValue,
  data,
  getTruckData,
  ascending,
  setAscending,
}: ProductResponseDataProps) => {
  const onclickSortingButton = () => {
    if (setAscending) {
      setAscending(!ascending);
    }
  };
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPoppup, setIsOpenPopup] = useState(false);
  const [excelDownloadParams, setExcelDownloadParams] = useState<ProductExcelParams>({});
  const [isManageMemoModalOpen, setIsManageMemoModalOpen] = useState(false);
  const [purchaseManageComment, setPurchaseManageComment] = useState('');
  const [selectedProductId, setSelectedProductId] = useState('');
  const [productFilterEnum] = useAtom(productsEnumAtom);
  const [isOpenSalesPeopleChangePopup, setIsOpenSalesPeopleChangePopup] = useState(false);

  const [basicPopupData, setBasicPopupData] = useState({
    title: '',
    handleOk: () => {},
    handleCancel: () => {},
  });

  const convertAssuranceFilterInfoSelectOptions = (productFilterEnum: ProductsFilterInfoType) => {
    return {
      salesPeople: convertToSalesPeopleOptions(productFilterEnum.salesPeople),
    };
  };

  const selectOptions = convertAssuranceFilterInfoSelectOptions(productFilterEnum);

  const onChangeSelect = (value: string, record: ProductListDataType) => {
    const request = {
      id: String(record.id),
      salesPeopleId: value,
    };
    setBasicPopupData({
      title: '담당자를 변경하시겠어요?',
      handleOk: () => onClickSalesPeopleChangeButton(request),
      handleCancel: () => onClickCancelButton(),
    });
    setIsOpenSalesPeopleChangePopup(true);
  };
  const onClickCancelButton = () => {
    setIsOpenSalesPeopleChangePopup(false);
  };

  const onClickSalesPeopleChangeButton = (requestData: { id: string; salesPeopleId: string }) => {
    patchSalesPeopleModifyMutation.mutate(requestData);
  };

  const patchSalesPeopleModifyMutation = useMutation(
    (requestData: { id: string; salesPeopleId: string }) => patchProductsDetail(requestData.id, requestData),
    {
      onSuccess: () => {
        message.success('담당자가 변경되었습니다.', 2);
        getTruckData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
      onSettled: () => {
        setIsOpenSalesPeopleChangePopup(false);
      },
    },
  );

  const columns: TableColumnsType<ProductListDataType> = [
    {
      title: '제시번호',
      dataIndex: 'productsNumber',
    },
    {
      title: '차량명',
      dataIndex: 'truckName',
    },
    {
      title: '차량번호',
      dataIndex: 'truckNumber',
    },
    {
      title: '상태',
      dataIndex: 'status',
    },
    {
      title: '제조사',
      dataIndex: 'manufacturerName',
    },
    {
      title: '모델',
      dataIndex: 'modelName',
    },
    {
      title: '연식',
      dataIndex: 'firstRegistrationDate',
    },
    {
      title: '적재함 길이',
      dataIndex: 'loadedInnerLength',
    },
    {
      title: '변속기',
      dataIndex: 'transmission',
    },
    {
      title: '주행거리',
      dataIndex: 'distance',
    },
    {
      title: '판매가격',
      dataIndex: 'price',
    },
    {
      title: <SortingButton title="등록일자" isAscending={ascending} onclick={onclickSortingButton} />,
      dataIndex: 'createdDate',
    },
    {
      title: '영업 담당자',
      dataIndex: 'salesPeople',
      render: (text, record) => (
        <>
          <Select
            className="mr-4 my-2"
            style={{ width: 200 }}
            placeholder="배너 노출 위치"
            optionFilterProp="children"
            options={selectOptions.salesPeople}
            value={record?.salesPeople}
            onChange={(value) => onChangeSelect(value, record)}
            onClick={(e) => e.stopPropagation()}
          ></Select>
        </>
      ),
    },
    {
      title: '삭제여부',
      dataIndex: 'isDelete',
      render: (text, record) => (record.isDelete == true ? '삭제' : '미삭제'),
    },
    {
      title: '더운반 차량 여부',
      dataIndex: 'isTheUnbanProduct',
      render: (text, record) => (record.isTheUnbanProduct == true ? '더운반' : '자체'),
    },
    {
      title: '구매자',
      dataIndex: 'buyer',
    },
    {
      title: '관리메모',
      dataIndex: 'productPurchaseManage',
      render: (text, record) => (
        <>
          {record?.productManage?.comment ? (
            <>
              <p
                className="font-bold mr-3 overflow-hidden whitespace-no-wrap text-ellipsis break-all"
                style={{
                  maxWidth: '500px',
                  maxHeight: '200px',
                }}
              >
                {record?.productManage?.comment}
              </p>

              <Button onClick={(e) => onClickMemoBtn(e, record)}>수정</Button>
            </>
          ) : (
            <Button onClick={(e) => onClickMemoBtn(e, record)}>작성</Button>
          )}
        </>
      ),
    },
  ];

  const onClickMemoBtn = (e: React.MouseEvent<HTMLElement, MouseEvent>, data: ProductListDataType) => {
    setPurchaseManageComment(data.productManage?.comment);
    setSelectedProductId(String(data.id));
    setIsManageMemoModalOpen(true);
    e.stopPropagation();
  };

  const updatedTableData =
    data?.data?.map((item) => ({
      id: item.id,
      key: item.id,
      productsNumber: item.productsNumber,
      truckName: item.truckName,
      truckNumber: item.truckNumber,
      status: item.status?.desc || '',
      buyer: item.buyer ? (item.isTheUnbanBuyer ? '(더운반)' + item.buyer.name : item.buyer.name) : '-',
      manufacturerName: item.manufacturerName,
      modelName: item.modelName,
      firstRegistrationDate: getFormatDate(String(item.firstRegistrationDate), ['year', 'month'], true),
      loadedInnerLength: item.loadedInnerLength,
      transmission: item.transmission?.desc || '',
      distance: formatNumberWithComma(String(item.distance)),
      price: item.price,
      createdDate: item.createdDate,
      salesPeople: item.salesPeople?.id ? `${item.salesPeople?.name}(${item.salesPeople?.phoneNumber})` : '-',
      productManage: item.productPurchaseManage,
      isDelete: item.isDelete,
      isTheUnbanProduct: item.isTheUnbanProduct,
    })) || [];

  const [tableData, setTableData] = useState<ProductListDataType[]>(updatedTableData);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowProps = (record: ProductListDataType) => ({
    onClick: () => {
      navigate(`/vehicles/assurance/${record.id}`);
    },
  });

  const rowSelection: TableRowSelection<ProductListDataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onClickDelete = () => {
    setIsOpenPopup(false);

    if (selectedRowKeys.length > 0) {
      const [firstSelectedId] = selectedRowKeys;
      mutate(String(firstSelectedId));
    } else {
      message.error('차량이 선택되지 않았어요. 삭제할 차량을 선택해주세요.');
    }
  };

  const { mutate } = useMutation(deleteProduct, {
    onSuccess: () => {
      message.success('삭제되었습니다.', 2);
      getTruckData();
    },
    onError: (error: AxiosError) => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const hasSelected = selectedRowKeys.length > 0;

  const showConfirm = () => {
    setIsOpenPopup(true);
  };

  const onMoveRegisterPage = () => {
    navigate('/vehicles/assurance/form');
  };

  const handleManageModalClose = () => {
    setIsManageMemoModalOpen(false);
  };

  const patchPurchaseManageCommentMutation = useMutation(
    (requestData: { id: string; productPurchaseManageComment: string }) =>
      patchProductsDetail(String(requestData.id), requestData),
    {
      onSuccess: () => {
        message.success('관리메모가 작성되었어요.', 2);
        getTruckData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const handleManageSave = () => {
    if (selectedProductId) {
      const request: any = {};
      request.id = selectedProductId;
      request.productPurchaseManageComment = purchaseManageComment;
      patchPurchaseManageCommentMutation.mutate(request);
    }
    setIsManageMemoModalOpen(false);
  };

  const onChangeComment = (comment: string) => {
    setPurchaseManageComment(comment);
  };

  useEffect(() => {
    setExcelDownloadParams({
      productNumber: selectedFilterValue?.productNumber ? String(selectedFilterValue?.productNumber) : '',
      truckName: selectedFilterValue?.truckName || '',
      productsStatus: selectedFilterValue?.status || '',
      type: selectedFilterValue?.productsType || '',
      manufacturerId: selectedFilterValue?.manufacturerCategories || '',
      modelId: selectedFilterValue?.model || '',
      tons: selectedFilterValue?.tons ? String(selectedFilterValue?.tons) : '',
      loaded: selectedFilterValue?.loaded || '',
      loadedInnerLength: selectedFilterValue?.loadedInnerLength ? String(selectedFilterValue?.loadedInnerLength) : '',
      axis: selectedFilterValue?.axis || '',
      transmission: selectedFilterValue?.transmission || '',
      startDate: selectedFilterValue?.startDate || '',
      endDate: selectedFilterValue?.endDate || '',
      salesType: 'ASSURANCE',
      externalClientsName: selectedFilterValue?.externalClientsName || '',
    });
  }, [selectedFilterValue]);

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체차량</span> {data?.totalElements}대
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}대 선택됨` : ''}</span>
          </div>
          <div>
            <ExcelDownloadButton
              apiPath="/admin/v1/products/excel"
              params={excelDownloadParams}
              buttonText="엑셀 다운로드"
            ></ExcelDownloadButton>
            <Button className="mr-2" onClick={onMoveRegisterPage} style={{ borderColor: '#1890ff', color: '#1890ff' }}>
              등록
            </Button>
            <Button danger onClick={showConfirm}>
              삭제
            </Button>
          </div>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          onRow={rowProps}
          pagination={false}
        />
      </div>
      <BasicPopup
        isOpen={isOpenPoppup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="정말 차량을 삭제하시겠어요?"
        content=""
        handleOk={() => onClickDelete()}
        handleCancel={() => setIsOpenPopup(false)}
      ></BasicPopup>
      <Modal
        title="메모 보기"
        open={isManageMemoModalOpen}
        onCancel={handleManageModalClose}
        footer={[
          <Button key="close" onClick={handleManageModalClose}>
            닫기
          </Button>,
          <Button key="save" type="primary" className="bg-[#1890ff] mx-2" onClick={handleManageSave}>
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={purchaseManageComment}
          onChange={(e) => onChangeComment(e.target.value)}
          placeholder="메모 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>

      <BasicPopup
        isOpen={isOpenSalesPeopleChangePopup}
        setIsOpen={setIsOpenSalesPeopleChangePopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title={basicPopupData.title}
        content=""
        handleOk={() => basicPopupData.handleOk()}
        handleCancel={() => basicPopupData.handleCancel()}
      ></BasicPopup>
    </>
  );
};

export default AssuranceSearchResult;
