import { convertToSalesPeopleOptions, convertToSelectOptions, formatNumberWithComma } from '@/utils/common';
import { Button, Col, Divider, Input, InputNumber, Radio, Row, Select, message } from 'antd';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';

import { postProducts } from '@/api/public';
import CheckBoxComponent from '@/components/Common/CheckBoxComponent';
import DragAndDropWrapper from '@/components/Common/DragAndDropWrapper';
import FileUploader from '@/components/Common/FileUploader';
import LoadingSpinner from '@/components/Common/Loading/LoadingSpinner';
import MultiFileUploader from '@/components/Common/MultiFileUploader';
import { COMMON_TOAST_ERROR_MESSAGE, NO_REQUIRED_VALUE_ERROR_MESSAGE } from '@/const/errorMessage';
import { productsEnumAtom } from '@/store/products';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { RadioChangeEvent } from 'antd';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;

const imageTypeList = [
  'FRONT_SIDE_IMAGE',
  'BACK_SIDE_IMAGE',
  'FRONT_IMAGE',
  'BACK_IMAGE',
  'TIRE_IMAGE',
  'ENGINE_IMAGE',
  'INSIDE_IMAGE',
  'DASHBOARD_IMAGE',
  'SHEET_IMAGE',
  'OPTION_IMAGE',
  'OPTION_IMAGE',
  'OPTION_IMAGE',
  'OPTION_IMAGE',
  'OPTION_IMAGE',
  'OPTION_IMAGE',
];

const DraggableUploadItem = ({
  truckNumber,
  item,
  index,
  setIsDisabledEditBtn,
  productImagesList,
  setProductImagesList,
}: any) => {
  const [file, setFile] = useState(item);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move',
  };

  useEffect(() => {
    const updatedList = productImagesList.map((image: ProductImage) =>
      image.id === file.id ? { ...image, value: file.value } : image,
    );

    setProductImagesList(updatedList);
  }, [file]);

  return (
    <div
      id={item.id}
      ref={setNodeRef}
      className={`mb-8 ${isDragging ? 'is-dragging' : ''}`}
      style={style}
      {...attributes}
      {...listeners}
    >
      <FileUploader
        type="productImage"
        isShowImage={true}
        imageType={imageTypeList[index]}
        truckNumber={truckNumber}
        fileData={file}
        setFileData={setFile}
        fileNameMaxLength={160}
        setIsDisabledEditBtn={setIsDisabledEditBtn}
      ></FileUploader>
    </div>
  );
};

const BasicInfo = ({ salesType }: ProductRegisterDataProps) => {
  const labelStyle: React.CSSProperties = { padding: '8px 0', fontWeight: '700' };
  const style: React.CSSProperties = { padding: '8px 0' };

  const [loading, setLoading] = useState(false);
  const [productFilterEnum, setProductFilterEnum] = useAtom(productsEnumAtom);
  const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(false);
  const navigate = useNavigate();

  const [certificateData, setCerticicateData] = useState({
    index: 0,
    id: 'certificateImageUrl',
    name: '차량등록증',
    value: '',
  });

  const [engineVideoData, setEngineVideoData] = useState({
    index: 11,
    id: 'engineVideoUrl',
    name: '엔진 영상',
    value: '',
  });

  const keyValueList: KeyValueListType = {
    frontSideImageUrl: '앞측면',
    backSideImageUrl: '후측면',
    frontImageUrl: '앞면',
    backImageUrl: '후면',
    tireImageUrl: '타이어',
    engineImageUrl: '엔진',
    insideImageUrl: '실내',
    dashboardImageUrl: '계기판',
    sheetImageUrl: '시트',
    opion1: '옵션1',
    opion2: '옵션2',
    opion3: '옵션3',
    opion4: '옵션4',
    opion5: '옵션5',
    opion6: '옵션6',
    opion7: '옵션7',
  };

  const productImageData = Object.entries(keyValueList).map(([key, value], index) => ({
    id: key,
    name: keyValueList[key],
    value: '',
    index: index,
  }));

  const [productImagesList, setProductImagesList] = useState<ProductImage[]>(productImageData);

  const convertManufacturerCategoriesToSelectOptions = (
    manufacturerCategoriesWithModels: ManufacturerCategoriesWithModels[],
  ): { value: string; label: string }[] => {
    return manufacturerCategoriesWithModels.map((item) => ({
      value: item.manufacturerCategories.id.toString(),
      label: item.manufacturerCategories.name,
    }));
  };

  const convertModelsToSelectOptions = (
    manufacturerCategoriesWithModels: ManufacturerCategoriesWithModels[],
    selectedManufacturerCategoryId: string,
  ): { value: string; label: string }[] => {
    const selectedManufacturerCategory = manufacturerCategoriesWithModels.find(
      (item) => item.manufacturerCategories.id.toString() === selectedManufacturerCategoryId,
    );
    return selectedManufacturerCategory
      ? selectedManufacturerCategory.model.map((model) => ({
          value: model.id.toString(),
          label: model.name,
        }))
      : [];
  };

  const convertProductsFilterInfoToSelectOptions = (productFilterEnum: ProductsFilterInfoType) => {
    return {
      axis: convertToSelectOptions(productFilterEnum.axis),
      loaded: convertToSelectOptions(productFilterEnum.loaded),
      manufacturerCategories: convertManufacturerCategoriesToSelectOptions(
        productFilterEnum.manufacturerCategoriesWithModels,
      ),
      model: convertModelsToSelectOptions(
        productFilterEnum.manufacturerCategoriesWithModels,
        selectedManufacturerCategoryId,
      ),
      productsType: convertToSelectOptions(productFilterEnum.productsType),
      transmission: convertToSelectOptions(productFilterEnum.transmission),
      locate: convertToSelectOptions(productFilterEnum.locate),
      fuel: convertToSelectOptions(productFilterEnum.fuel),
      color: convertToSelectOptions(productFilterEnum.color),
      salesPeople: convertToSalesPeopleOptions(productFilterEnum.salesPeople),
      salesType: convertToSelectOptions(productFilterEnum.salesType),
    };
  };
  const [selectedValue, setSelectedValue] = useState<SelectedValue>({
    carNumber: null,
    ownerName: '',
    year: '',
    firstRegistrationDate: '',
    axis: null,
    distance: null,
    tons: null,
    manufacturerCategories: null,
    model: null,
    productsType: null,
    price: null,
    fuel: null,
    transmission: null,
    tireStatus: '',
    garage: null,
    power: '',
    loaded: null,
    loadedInnerLength: null,
    loadedInnerArea: null,
    loadedInnerHeight: null,
    color: null,
    isAccident: false,
    accidentContents: '',
    transportStartLocate: null,
    transportEndLocate: null,
    transportGoods: '',
    salesPeople: null,
    detailContent: '',
    salesType: salesType,
    normalOptionEtc: '',
    additionalOptionEtc: '',
    breakOptionEtc: '',
  });

  const [selectedManufacturerCategoryId, setSelectedManufacturerCategoryId] = useState<string>(
    String(selectedValue?.manufacturerCategories),
  );

  const convertModelsOptions = convertModelsToSelectOptions(
    productFilterEnum.manufacturerCategoriesWithModels,
    selectedManufacturerCategoryId,
  );

  const selectOptions = convertProductsFilterInfoToSelectOptions(productFilterEnum);

  const onChangeInput = (name: string, value: string) => {
    setIsDisabledEditBtn(false);

    if (name === 'manufacturerCategories') {
      setSelectedManufacturerCategoryId(value);
      setSelectedValue((prevOptions) => ({
        ...prevOptions,
        [name]: value,
        model: null,
      }));
    } else if (name === 'distance') {
      const newValueWithoutCommas = value.replace(/,/g, '');

      setSelectedValue((prevOptions) => ({
        ...prevOptions,
        [name]: Number(newValueWithoutCommas),
      }));
    } else {
      setSelectedValue((prevOptions) => ({
        ...prevOptions,
        [name]: value,
      }));
    }
  };

  const postProductsMutation = useMutation(postProducts, {
    onSuccess: (data) => {
      message.success('기본정보가 등록되었습니다.', 2);
      setIsDisabledEditBtn(true);
      setLoading(false);

      const productId = data.data.id;
      if (salesType === 'ASSURANCE') {
        navigate(`/vehicles/assurance/${productId}`);
      } else {
        navigate(`/vehicles/consignment/${productId}`);
      }
    },
    onError: (error: AxiosError) => {
      setLoading(false);
      const errorCodes = ['MODEL_NOT_FOUND', 'MISSING_CAR_NUMBER', 'NOT_MATCH_OWNER_NAME'];
      if (error && errorCodes.includes(error.code || '')) {
        message.error(error.message, 2);
      } else {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      }
    },
  });

  const isValidateChecked = () => {
    const {
      carNumber,
      ownerName,
      tons,
      distance,
      model,
      garage,
      axis,
      transmission,
      fuel,
      loaded,
      loadedInnerArea,
      loadedInnerLength,
      loadedInnerHeight,
      power,
      color,
      price,
      salesPeople,
    } = selectedValue;

    const valuesToCheckCarInfo = [
      carNumber,
      ownerName,
      tons,
      distance,
      model,
      garage,
      axis,
      transmission,
      fuel,
      loaded,
      loadedInnerLength,
      power,
      color,
      price,
      salesPeople,
      productImagesList[0].value,
      productImagesList[1].value,
      productImagesList[2].value,
    ];

    if (!hasLoadedOnlyLength()) {
      valuesToCheckCarInfo.push(loadedInnerArea, loadedInnerHeight);
    } else {
      const indexLoadedInnerArea = valuesToCheckCarInfo.indexOf(loadedInnerArea);
      const indexLoadedInnerHeight = valuesToCheckCarInfo.indexOf(loadedInnerHeight);

      if (indexLoadedInnerArea !== -1) {
        valuesToCheckCarInfo.splice(indexLoadedInnerArea, 1);
      }

      if (indexLoadedInnerHeight !== -1) {
        valuesToCheckCarInfo.splice(indexLoadedInnerHeight, 1);
      }
    }

    if (
      valuesToCheckCarInfo.some((value) => value == undefined || value == null || value === '' || Number(value) <= 0)
    ) {
      message.error(NO_REQUIRED_VALUE_ERROR_MESSAGE, 2);
      return false;
    } else {
      return true;
    }
  };

  const hasLoadedOnlyLength = () => {
    if (
      selectedValue.loaded === 'CARGO' ||
      selectedValue.loaded === 'TANKLORRY' ||
      selectedValue.loaded === 'TRAILER' ||
      selectedValue.loaded === 'LADDER' ||
      selectedValue.loaded === 'AUTOLADDER' ||
      selectedValue.loaded === 'TONGS'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onClickRegisterBtn = () => {
    const request: ProductRegisterRequest = {
      axis: selectedValue.axis,
      backImageUrl: '',
      backSideImageUrl: '',
      carNumber: selectedValue.carNumber || '',
      certificateImageUrl: certificateData.value,
      dashboardImageUrl: '',
      distance: selectedValue.distance || 0,
      engineImageUrl: '',
      engineVideoOriginName: '',
      engineVideoUrl: engineVideoData.value,
      frontImageUrl: '',
      frontSideImageUrl: '',
      fuel: selectedValue.fuel,
      insideImageUrl: '',
      loaded: selectedValue.loaded,
      loadedInnerArea: selectedValue.loadedInnerArea,
      loadedInnerHeight: selectedValue.loadedInnerHeight,
      loadedInnerLength: selectedValue.loadedInnerLength,
      modelId: Number(selectedValue.model) || 0,
      optionImageUrls: [
        productImagesList[9]?.value,
        productImagesList[10]?.value,
        productImagesList[11]?.value,
        productImagesList[12]?.value,
        productImagesList[13]?.value,
        productImagesList[14]?.value,
        productImagesList[15]?.value,
      ],
      ownerName: selectedValue.ownerName || '',
      power: selectedValue.power,
      price: selectedValue.price,
      salesPeopleId: selectedValue.salesPeople,
      sheetImageUrl: '',
      tireImageUrl: '',
      tons: selectedValue.tons,
      transmission: selectedValue.transmission,
      accident: selectedValue.isAccident,
      accidentContents: selectedValue.accidentContents,
      detailContents: selectedValue.detailContent || '',
      carOption: {
        normalOption: {
          option: selectedNormalOptionList,
          etc: selectedValue.normalOptionEtc || '',
        },
        additionalOption: {
          option: selectedAdditionalOptionList,
          etc: selectedValue.additionalOptionEtc || '',
        },
        breakOption: {
          option: selectedBreakOptionList,
          etc: selectedValue.breakOptionEtc || '',
        },
      },
      tireStatus: selectedValue.tireStatus,
      transportGoods: selectedValue.transportGoods,
      transportStartLocate: selectedValue.transportStartLocate,
      transportEndLocate: selectedValue.transportEndLocate,
      maintenanceData: {
        maintenanceCategories: selectedMaintenanceList,
        etc: selectedValue.maintenanceEtc || '',
      },
      salesType: salesType || '',
    };

    if (JSON.stringify(productImageData) !== JSON.stringify(productImagesList)) {
      request.frontSideImageUrl = productImagesList[0].value;
      request.backSideImageUrl = productImagesList[1].value;
      request.frontImageUrl = productImagesList[2].value;
      request.backImageUrl = productImagesList[3].value;
      request.tireImageUrl = productImagesList[4].value;
      request.engineImageUrl = productImagesList[5].value;
      request.insideImageUrl = productImagesList[6].value;
      request.dashboardImageUrl = productImagesList[7].value;
      request.sheetImageUrl = productImagesList[8].value;
    }

    if (isValidateChecked()) {
      setLoading(true);
      postProductsMutation.mutate(request);
    }
  };

  const [selectedMaintenanceList, setSelectedMaintenanceList] = useState<string[]>([]);
  const [selectedNormalOptionList, setSelectedNormalOptionList] = useState<string[]>([]);
  const [selectedAdditionalOptionList, setSelectedAdditionalOptionList] = useState<string[]>([]);
  const [selectedBreakOptionList, setSelectedBreakOptionList] = useState<string[]>([]);

  const onChangeRadio = (key: string, e: RadioChangeEvent) => {
    setIsDisabledEditBtn(false);
    const val = e.target.value;

    if (key === 'tireStatus') {
      setSelectedValue({ ...selectedValue, tireStatus: val });
    } else if (key === 'isAccident') {
      setSelectedValue((prev) => {
        const updatedValue = { ...prev, isAccident: val };
        if (val === false) {
          updatedValue.accidentContents = null;
        }
        return updatedValue;
      });
    }
  };

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [loading]);

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량번호</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Input
              className="mr-1"
              placeholder="차량번호 입력"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.carNumber || ''}
              onChange={(e) => onChangeInput('carNumber', String(e.target.value))}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>소유자 명</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Input
              className="mr-1"
              placeholder="소유자 명 입력"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.ownerName}
              onChange={(e) => onChangeInput('ownerName', String(e.target.value))}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>판매 유형</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="판매 유형"
              optionFilterProp="children"
              options={selectOptions.salesType}
              value={selectedValue.salesType}
              onChange={(value) => onChangeInput('productsType', value)}
              disabled
            />
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>톤수</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-1"
              placeholder="톤수 입력"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.tons}
              onChange={(value) => onChangeInput('tons', String(value))}
            />
            <span className="mr-4">t</span>
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>주행거리</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Input
              className="mr-1"
              placeholder="주행거리 입력"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={formatNumberWithComma(String(selectedValue?.distance || ''), true)}
              onChange={(e) => onChangeInput('distance', String(e.target.value))}
            />
            <span className="mr-4">km</span>
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>제조사</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="제조사"
              optionFilterProp="children"
              options={selectOptions.manufacturerCategories}
              value={selectedValue.manufacturerCategories}
              onChange={(value) => onChangeInput('manufacturerCategories', String(value))}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>모델</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="모델"
              optionFilterProp="children"
              options={convertModelsOptions}
              value={selectedValue.model}
              onChange={(value) => onChangeInput('model', String(value))}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차고지</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="차고지"
              optionFilterProp="children"
              options={selectOptions.locate}
              value={selectedValue.garage}
              onChange={(value) => onChangeInput('garage', value)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>가변축</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="가변축"
              optionFilterProp="children"
              options={selectOptions.axis}
              value={selectedValue.axis}
              onChange={(value) => onChangeInput('axis', value)}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>변속기</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="변속기"
              optionFilterProp="children"
              options={selectOptions.transmission}
              value={selectedValue.transmission}
              onChange={(value) => onChangeInput('transmission', value)}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>연료</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="연료"
              optionFilterProp="children"
              options={selectOptions.fuel}
              value={selectedValue.fuel}
              onChange={(value) => onChangeInput('fuel', value)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>적재함 종류</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="적재함 종류"
              optionFilterProp="children"
              options={selectOptions.loaded}
              value={selectedValue.loaded}
              onChange={(value) => onChangeInput('loaded', value)}
            />
          </div>
        </Col>
        {hasLoadedOnlyLength() ? (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>적재함 길이</div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <InputNumber
                  className="mr-1"
                  placeholder="길이"
                  style={{ width: '100%', minWidth: 60, maxWidth: 72 }}
                  value={selectedValue?.loadedInnerLength}
                  onChange={(value) => onChangeInput('loadedInnerLength', String(value))}
                />
                <span className="mr-4">m</span>
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>적재함 길이/너비/높이</div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <InputNumber
                  className="mr-1"
                  placeholder="길이"
                  style={{ width: '100%', minWidth: 60, maxWidth: 72 }}
                  value={selectedValue?.loadedInnerLength}
                  onChange={(value) => onChangeInput('loadedInnerLength', String(value))}
                />
                <InputNumber
                  className="mr-1"
                  placeholder="너비"
                  style={{ width: '100%', minWidth: 60, maxWidth: 72 }}
                  value={selectedValue?.loadedInnerArea}
                  onChange={(value) => onChangeInput('loadedInnerArea', String(value))}
                />
                <InputNumber
                  className="mr-1"
                  placeholder="높이"
                  style={{ width: '100%', minWidth: 60, maxWidth: 72 }}
                  value={selectedValue?.loadedInnerHeight}
                  onChange={(value) => onChangeInput('loadedInnerHeight', String(value))}
                />
                <span className="mr-4">m</span>
              </div>
            </Col>
          </>
        )}

        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>마력수</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-4"
              placeholder="마력수"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.power}
              onChange={(value) => onChangeInput('power', String(value))}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량 색상</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="차량 색상"
              optionFilterProp="children"
              options={selectOptions.color}
              value={selectedValue.color}
              onChange={(value) => onChangeInput('color', value)}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>판매가격</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-1"
              placeholder="판매 가격 입력"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.price}
              onChange={(value) => onChangeInput('price', String(value))}
            />
            <span className="mr-4">만원</span>
          </div>
        </Col>

        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>영업 담당자</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              style={{ width: '100%', minWidth: 86, maxWidth: 200 }}
              placeholder="영업 담당자"
              optionFilterProp="children"
              options={selectOptions.salesPeople}
              value={selectedValue.salesPeople}
              onChange={(value) => onChangeInput('salesPeople', String(value))}
            />
          </div>
        </Col>
      </Row>

      <Divider></Divider>

      <span className="text-red-500 text-xs">*차량번호 입력 후, 업로드해주세요.</span>
      <Row>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량등록증</div>
        </Col>
        <Col className="gutter-row" span={9}>
          <FileUploader
            type="productImage"
            isShowImage={false}
            imageType="CERTIFICATE_IMAGE"
            truckNumber={selectedValue?.carNumber || ''}
            fileData={certificateData}
            setFileData={setCerticicateData}
            fileNameMaxLength={340}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
          ></FileUploader>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>엔진 영상</div>
        </Col>
        <Col className="gutter-row" span={9}>
          <FileUploader
            type="productImage"
            isShowImage={false}
            imageType="ENGINE_VIDEO"
            truckNumber={selectedValue?.carNumber || ''}
            fileData={engineVideoData}
            setFileData={setEngineVideoData}
            fileNameMaxLength={340}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
          ></FileUploader>
        </Col>
      </Row>

      <Row>
        <Col className="gutter-row" span={24}>
          <div style={labelStyle}>
            <span className="mr-2">차량 사진</span>
            <MultiFileUploader
              truckNumber={selectedValue?.carNumber || ''}
              fileNameMaxLength={340}
              setIsDisabledEditBtn={setIsDisabledEditBtn}
              setProductImagesList={setProductImagesList}
            ></MultiFileUploader>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="gutter-row" span={24}>
          <div className="flex flex-wrap">
            <>
              <div className="z-10 flex flex-wrap">
                <DragAndDropWrapper
                  productImagesList={productImagesList}
                  setProductImagesList={setProductImagesList}
                  setIsDisabledEditBtn={setIsDisabledEditBtn}
                >
                  {productImagesList?.map((item, index) => (
                    <DraggableUploadItem
                      key={`${item.id}_${item.value}_${productImagesList.length}`}
                      item={item}
                      truckNumber={selectedValue?.carNumber}
                      index={index}
                      productImagesList={productImagesList}
                      setProductImagesList={setProductImagesList}
                      setIsDisabledEditBtn={setIsDisabledEditBtn}
                    />
                  ))}
                </DragAndDropWrapper>
              </div>
              <div className="z-0 absolute flex flex-wrap">
                {productImageData?.map((item, index) => (
                  <div className="flex flex-col text-center " key={index}>
                    <div className="w-[198px] h-[132px] m-2">
                      <div className="w-full h-full bg-[#DCDCDC]" />
                    </div>

                    <div className="mb-4 h-[16px] mt-8">{item?.name}</div>
                  </div>
                ))}
              </div>
            </>
          </div>
        </Col>
      </Row>

      <Divider></Divider>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>사고 유무</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Radio.Group onChange={(e) => onChangeRadio('isAccident', e)} value={selectedValue?.isAccident}>
              <Radio value={true}>있음</Radio>
              <Radio value={false}>없음</Radio>
            </Radio.Group>
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>사고 상세 내용</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <TextArea
            value={selectedValue.accidentContents || ''}
            onChange={(e) => onChangeInput('accidentContents', e.target.value)}
            placeholder="사고 상세 내용 입력"
            autoSize={{ minRows: 5, maxRows: 5 }}
            disabled={!selectedValue?.isAccident}
          />
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>타이어 상태</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Radio.Group onChange={(e) => onChangeRadio('tireStatus', e)} value={selectedValue?.tireStatus}>
              <Radio value="GOOD">상</Radio>
              <Radio value="FAIR">중</Radio>
              <Radio value="POOR">하</Radio>
            </Radio.Group>
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>운송 물품</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Input
              className="mr-1"
              placeholder="운송 물품"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.transportGoods || ''}
              onChange={(e) => onChangeInput('transportGoods', e.target.value)}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>주요 운행 구간</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              style={{ width: '100%', minWidth: 86, maxWidth: 108 }}
              placeholder="OO도"
              optionFilterProp="children"
              options={selectOptions.locate}
              value={selectedValue.transportStartLocate}
              onChange={(value) => onChangeInput('transportStartLocate', value)}
            />
            <span className="mx-2">~</span>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 108 }}
              placeholder="OO도"
              optionFilterProp="children"
              options={selectOptions.locate}
              value={selectedValue.transportEndLocate}
              onChange={(value) => onChangeInput('transportEndLocate', value)}
            />
          </div>
        </Col>
      </Row>

      <Divider></Divider>

      <Row>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량 정비 이력</div>
        </Col>
        <Col className="gutter-row mb-8" span={21}>
          <CheckBoxComponent
            optionList={productFilterEnum.maintenanceCategories}
            selectedList={selectedMaintenanceList}
            setSelectedList={setSelectedMaintenanceList}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
          ></CheckBoxComponent>
          <div className="flex" style={style}>
            <div className="w-[60px]">직접입력 </div>
            <Input
              className="mr-1"
              placeholder="직접입력"
              style={{ width: '100%' }}
              value={selectedValue.maintenanceEtc || ''}
              onChange={(e) => onChangeInput('maintenanceEtc', e.target.value)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량 옵션</div>
        </Col>
        <Col className="gutter-row mb-8" span={21}>
          <CheckBoxComponent
            optionList={productFilterEnum.normalOption}
            selectedList={selectedNormalOptionList}
            setSelectedList={setSelectedNormalOptionList}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
          ></CheckBoxComponent>
          <div className="flex" style={style}>
            <div className="w-[60px]">직접입력 </div>
            <Input
              className="mr-1"
              placeholder="직접입력"
              style={{ width: '100%' }}
              value={selectedValue.normalOptionEtc || ''}
              onChange={(e) => onChangeInput('normalOptionEtc', e.target.value)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>추가 옵션</div>
        </Col>
        <Col className="gutter-row mb-8" span={21}>
          <CheckBoxComponent
            optionList={productFilterEnum.additionalOption}
            selectedList={selectedAdditionalOptionList}
            setSelectedList={setSelectedAdditionalOptionList}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
          ></CheckBoxComponent>
          <div className="flex" style={style}>
            <div className="w-[60px]">직접입력 </div>
            <Input
              className="mr-1"
              placeholder="직접입력"
              style={{ width: '100%' }}
              value={selectedValue.additionalOptionEtc || ''}
              onChange={(e) => onChangeInput('additionalOptionEtc', e.target.value)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>브레이크 옵션</div>
        </Col>
        <Col className="gutter-row mb-8" span={21}>
          <CheckBoxComponent
            optionList={productFilterEnum.breakOption}
            selectedList={selectedBreakOptionList}
            setSelectedList={setSelectedBreakOptionList}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
          ></CheckBoxComponent>
          <div className="flex" style={style}>
            <div className="w-[60px]">직접입력 </div>
            <Input
              className="mr-1"
              placeholder="직접입력"
              style={{ width: '100%' }}
              value={selectedValue.breakOptionEtc || ''}
              onChange={(e) => onChangeInput('breakOptionEtc', e.target.value)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량 상세 설명</div>
        </Col>
        <Col className="gutter-row" span={21}>
          <div style={style}>
            <TextArea
              className="mr-1"
              placeholder="차량 상세 설명"
              style={{ width: '100%' }}
              value={selectedValue?.detailContent || ''}
              onChange={(e) => onChangeInput('detailContent', e.target.value)}
              autoSize={{ minRows: 5 }}
            />
          </div>
        </Col>
      </Row>
      <Divider orientation="left"></Divider>
      <div className="flex justify-end">
        <Button
          type="primary"
          className="bg-[#1890ff] mx-2 w-20"
          disabled={isDisabledEditBtn}
          onClick={onClickRegisterBtn}
        >
          등록하기
        </Button>
      </div>
      <LoadingSpinner text="등록중입니다." spinning={loading}></LoadingSpinner>
    </>
  );
};

export default BasicInfo;
