import { LOOKUP, PURCHASE_INQUIRY } from '@/const/license';
import { Button, DatePicker, Input, InputNumber, Select } from 'antd';

import { licenseEnumAtom } from '@/store/license';
import { convertToSelectOptions } from '@/utils/common';
import { SearchOutlined } from '@ant-design/icons';
import { useAtom } from 'jotai';
import React from 'react';

const { Search } = Input;
const { RangePicker } = DatePicker;

interface SearchProps {
  menuType: string;
  selectedFilterValue: SelectedLicenseFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedLicenseFilterValue>>;
  getLicenseData: () => void;
}

const LicenseFilterSelector = ({
  menuType,
  selectedFilterValue,
  setSelectedFilterValue,
  getLicenseData,
}: SearchProps) => {
  const [productFilterInfo, setProductFilterInfo] = useAtom(licenseEnumAtom);

  const convertLicenseFilterInfoToSelectOptions = (licenseFilterInfo: LicenseFilterInfoType) => {
    return {
      licenseSalesType: convertToSelectOptions(licenseFilterInfo.licenseSalesType),
      licenseType: convertToSelectOptions(licenseFilterInfo.licenseType),
      locate: convertToSelectOptions(licenseFilterInfo.locate),
      status: convertToSelectOptions(licenseFilterInfo.status, ['ORIGIN_DATA_REGISTER', 'BEFORE_SALE']),
      useClassification: convertToSelectOptions(licenseFilterInfo.useClassification),
      licenseCounselStatus: convertToSelectOptions(licenseFilterInfo.licenseCounselStatus),
      isCompleted: [
        { value: 'true', label: '완료' },
        { value: 'false', label: '미완료' },
      ],
    };
  };

  const selectOptions = convertLicenseFilterInfoToSelectOptions(productFilterInfo);

  const onChangeSelect = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const handleInputNumberChange = (name: string, value: number | null) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
      model: null,
    }));
  };

  const handleInputStringChange = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      sellerKeyword: null,
      truckNumber: null,
      status: null,
      locate: null,
      tons: null,
      year: null,
      licenseSalesType: null,
      licenseType: null,
      useClassification: null,
      ascending: false,
    });
  };

  return (
    <div className="py-4">
      {menuType === LOOKUP && (
        <div className="mb-4">
          <Search
            className="mr-4"
            placeholder="판매자 정보(번호/이름)"
            allowClear
            onSearch={getLicenseData}
            style={{ width: 200 }}
            value={selectedFilterValue?.sellerKeyword || ''}
            onChange={(e) => handleInputStringChange('sellerKeyword', e.target.value)}
          />
          <Search
            className="mr-4"
            placeholder="제시번호 입력"
            allowClear
            onSearch={getLicenseData}
            style={{ width: 180 }}
            value={selectedFilterValue?.productNumber || ''}
            onChange={(e) => handleInputStringChange('productNumber', e.target.value)}
          />
          <Select
            className="mr-4"
            style={{ width: 100 }}
            placeholder="상태"
            options={selectOptions.status}
            value={selectedFilterValue.status}
            onChange={(value) => onChangeSelect('status', value)}
          />
        </div>
      )}

      <div className="mb-4 flex items-center">
        {menuType == PURCHASE_INQUIRY && (
          <Search
            className="mr-4"
            placeholder="요청자 정보(번호/이름)"
            allowClear
            onSearch={getLicenseData}
            style={{ width: 200 }}
            value={selectedFilterValue?.memberKeyword || ''}
            onChange={(e) => handleInputStringChange('memberKeyword', e.target.value)}
          />
        )}

        <Select
          className="mr-4"
          style={{ width: 180 }}
          placeholder="지역"
          optionFilterProp="children"
          options={selectOptions.locate}
          value={selectedFilterValue.locate}
          onChange={(value) => onChangeSelect('locate', value)}
        />
        <InputNumber
          className="mr-4 my-2"
          placeholder="톤수"
          style={{ width: 100 }}
          value={selectedFilterValue?.tons}
          onChange={(value) => handleInputNumberChange('tons', value)}
          suffix="t"
        />
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="번호판 종류"
          optionFilterProp="children"
          options={selectOptions.licenseType}
          value={selectedFilterValue.licenseType}
          onChange={(value) => onChangeSelect('licenseType', value)}
        />
        <InputNumber
          className="mr-4 my-2"
          placeholder="연식 입력"
          style={{ width: 180 }}
          value={selectedFilterValue?.year}
          onChange={(value) => handleInputNumberChange('year', Number(value))}
        />
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="거래방식"
          optionFilterProp="children"
          options={selectOptions.licenseSalesType}
          value={selectedFilterValue?.licenseSalesType}
          onChange={(value) => onChangeSelect('licenseSalesType', value)}
        />
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="용도"
          optionFilterProp="children"
          options={selectOptions.useClassification}
          value={selectedFilterValue?.useClassification}
          onChange={(value) => onChangeSelect('useClassification', value)}
        />
        {menuType === PURCHASE_INQUIRY && (
          <Select
            className="mr-4 my-2"
            style={{ width: 200 }}
            placeholder="문의 완료 여부"
            optionFilterProp="children"
            options={selectOptions.isCompleted}
            value={selectedFilterValue?.isCompleted}
            onChange={(value) => onChangeSelect('isCompleted', value)}
          />
        )}
        {menuType === LOOKUP && (
          <Select
            className="mr-4 my-2"
            style={{ width: 200 }}
            placeholder="상담 상태"
            optionFilterProp="children"
            options={selectOptions.licenseCounselStatus}
            value={selectedFilterValue?.licenseCounselStatus}
            onChange={(value) => onChangeSelect('licenseCounselStatus', value)}
          />
        )}
      </div>
      <div className="w-full flex justify-end mb-2">
        <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getLicenseData}>
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};

export default LicenseFilterSelector;
