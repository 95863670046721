import React, { useEffect, useState } from 'react';

import { getLicenses } from '@/api/public';
import LicenseFilterSelector from '@/components/License/LicenseFilterSelector';
import LicenseSearchResult from '@/components/License/List/LicenseSearchResult';
import { LOOKUP } from '@/const/license';
import { Pagination } from 'antd';

const LicenseList = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedLicenseFilterValue>({
    sellerKeyword: null,
    truckNumber: null,
    status: null,
    locate: null,
    tons: null,
    year: null,
    licenseSalesType: null,
    licenseType: null,
    useClassification: null,
    productNumber: null,
    licenseCounselStatus: null,
    ascending: false,
  });

  const [searchResultData, setSearchResultData] = useState<LicenseResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const getLicenseData = async (page?: number) => {
    const {
      sellerKeyword,
      truckNumber,
      status,
      locate,
      tons,
      year,
      licenseSalesType,
      licenseType,
      useClassification,
      productNumber,
      licenseCounselStatus,
      ascending,
    } = selectedFilterValue;
    const queryParams = new URLSearchParams({
      sellerKeyword: sellerKeyword || '',
      truckNumber: truckNumber || '',
      status: status || '',
      locate: locate || '',
      tons: tons ? String(tons) : '',
      year: year ? String(year) : '',
      licenseSalesType: licenseSalesType || '',
      licenseType: licenseType || '',
      useClassification: useClassification || '',
      productNumber: productNumber ? String(productNumber) : '',
      licenseCounselStatus: licenseCounselStatus ? licenseCounselStatus : '',
      ascending: String(ascending),
      page: String(page ? page : 1),
      size: '10',
    });
    const response = await getLicenses(queryParams);
    setSearchResultData(response.data);
  };

  useEffect(() => {
    getLicenseData(currentPage);
  }, [currentPage, selectedFilterValue.ascending]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const updateAscending = () => {
    setSelectedFilterValue({ ...selectedFilterValue, ascending: !selectedFilterValue.ascending });
  };

  return (
    <>
      <h2 className="text-gray-8">
        번호판 관리 / <span className="font-semibold">번호판 조회</span>
      </h2>
      <LicenseFilterSelector
        menuType={LOOKUP}
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getLicenseData={() => getLicenseData(currentPage)}
      ></LicenseFilterSelector>
      <LicenseSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getLicenseData={() => getLicenseData(currentPage)}
        ascending={selectedFilterValue.ascending}
        updateAscending={updateAscending}
      ></LicenseSearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default LicenseList;
