import { getMeta } from '@/api/public';
import {
  AlertOutlined,
  CarOutlined,
  ContainerOutlined,
  DashboardOutlined,
  FlagOutlined,
  LineChartOutlined,
  PayCircleOutlined,
  PictureOutlined,
  PieChartOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  SolutionOutlined,
  StockOutlined,
  TeamOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import React, { useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import useFetchUserInfo from '@/hooks/useFetchUserInfo';
import { bannerEnumAtom } from '@/store/banner';
import { capitalCounselServicesEnumAtom } from '@/store/capitalCounselServices';
import { dashboardFilterEnumAtom } from '@/store/dashboard';
import { jobEnumAtom } from '@/store/job';
import { licenseEnumAtom } from '@/store/license';
import { membersEnumAtom } from '@/store/member';
import { notificationEnumAtom } from '@/store/notification';
import { oneStopServicesEnumAtom } from '@/store/oneStopServices';
import { productInquiryEnumAtom } from '@/store/productInquiry';
import { productPurchaseManageEnumAtom } from '@/store/productPurchaseManage';
import { productsEnumAtom } from '@/store/products';
import { purchaseAccompanyingServicesEnumAtom } from '@/store/purchaseAccompanyingServices';
import { transferAgencyServicesEnumAtom } from '@/store/transferAgencyServices';
import { useAtom } from 'jotai';
import { useQuery } from 'react-query';
import { SvgLogo } from '../Icon';
import HeaderComponent from './HeaderComponent';

const { Content, Footer, Sider } = Layout;

const items = [
  { label: '대시보드', key: 'DASHBOARD', icon: <PieChartOutlined />, link: '/' },
  { label: '회원관리', key: 'MEMBER_MANAGEMENT', icon: <SolutionOutlined />, link: '/members' },
  {
    label: '차량관리',
    key: 'VEHICLE_MANAGEMENT',
    icon: <CarOutlined />,
    link: '/vehicles/all',
    children: [
      { label: '전체 차량', key: 'ALL_VEHICLES', link: '/vehicles/all' },
      { label: '직거래 차량', key: 'DIRECT_VEHICLES', link: '/vehicles/direct' },
      { label: '직트럭 상품용', key: 'ASSURANCE_VEHICLES', link: '/vehicles/assurance' },
      { label: '판매대행 차량', key: 'CONSIGNMENT_VEHICLES', link: '/vehicles/consignment' },
      { label: '매입견적 차량', key: 'SPEED_VEHICLES', link: '/vehicles/speed' },
      { label: '더운반 정산 대상용 차량', key: 'THEUNBAN_VEHICLES', link: '/vehicles/theunban' },
      { label: '모델 관리', key: 'MODEL_MANAGEMENT', link: '/vehicles/model' },
    ],
  },
  {
    label: '번호판 관리',
    key: 'LICENSE_PLATE_MANAGEMENT',
    icon: <DashboardOutlined />,
    link: '/license-plates',
    children: [
      { label: '번호판 조회', key: 'LICENSE_PLATE_INQUIRY', link: '/license-plates' },
      { label: '번호판 구매문의', key: 'LICENSE_PLATE_PURCHASE_INQUIRY', link: '/license-plates/inquiry' },
    ],
  },
  {
    label: '차량 구매문의 관리',
    key: 'PRODUCT_INQUIRY_MANAGEMENT',
    icon: <ReconciliationOutlined />,
    link: '/product-inquiry',
  },
  {
    label: '부가 서비스 관리',
    key: 'ADDITIONAL_SERVICE_MANAGEMENT',
    icon: <ContainerOutlined />,
    link: '/transfer-agency-services',
    children: [
      {
        label: '판매대행 서비스 관리',
        key: 'CONSIGNMENT_SERVICE_MANAGEMENT',
        link: '/one-stop-services',
      },
      {
        label: '구매동행 서비스 관리',
        key: 'PURCHASE_ACCOMPANYING_SERVICE_MANAGEMENT',
        link: '/purchase-accompanying-services',
      },
      { label: '이전대행 서비스 관리', key: 'TRANSFER_AGENCY_SERVICE_MANAGEMENT', link: '/transfer-agency-services' },
      {
        label: '대출상담 서비스 관리',
        key: 'CAPITAL_COUNSEL_SERVICE_MANAGEMENT',
        link: '/capital-counsel-services',
      },
    ],
  },

  { label: '시세관리', key: 'PRICE_MANAGEMENT', icon: <LineChartOutlined />, link: '/price-management' },
  { label: '배너관리', key: 'BANNER_MANAGEMENT', icon: <PictureOutlined />, link: '/banner' },
  { label: '일자리 관리', key: 'JOB_MANAGEMENT', icon: <WalletOutlined />, link: '/job' },
  { label: '공지사항 관리', key: 'NOTICE_MANAGEMENT', icon: <FlagOutlined />, link: '/notice' },
  { label: '알림 관리', key: 'NOTIFICATION_MANAGEMENT', icon: <AlertOutlined />, link: '/notification' },
  { label: '관리자 계정 관리', key: 'ADMIN_ACCOUNT_MANAGEMENT', icon: <TeamOutlined />, link: '/admin-accounts' },
  {
    label: '앱 다운로드 수 관리',
    key: 'APP_DOWNLOAD_COUNT_MANAGEMENT',
    icon: <StockOutlined />,
    link: '/app-download-count',
  },
  {
    label: '내부 데이터 관리',
    key: 'INTERNAL_DATA_MANAGEMENT',
    icon: <LineChartOutlined />,
    link: '/develop-data-management',
  },
  { label: '유류세 연동 보조금 관리', key: 'SUBSIDY_MANAGEMENT', icon: <PayCircleOutlined />, link: '/subsidy' },
  { label: '이용약관 관리', key: 'TERMS_MANAGEMENT', icon: <ReadOutlined />, link: '/terms-management' },
];

const MainLayout = () => {
  const setMemberData = useFetchUserInfo();

  const navigate = useNavigate();
  const [, setMemberFilterInfo] = useAtom(membersEnumAtom);
  const [, setProductFilterInfo] = useAtom(productsEnumAtom);
  const [, setLicenseFilterInfo] = useAtom(licenseEnumAtom);
  const [, setTransferAgencyServicesFilterInfo] = useAtom(transferAgencyServicesEnumAtom);
  const [, setOneStopServicesFilterInfo] = useAtom(oneStopServicesEnumAtom);
  const [, setProductInquiryFilterInfo] = useAtom(productInquiryEnumAtom);
  const [, setproductPurchaseManageFilterInfo] = useAtom(productPurchaseManageEnumAtom);
  const [, setJobFilterInfo] = useAtom(jobEnumAtom);
  const [, setBannerFilterInfo] = useAtom(bannerEnumAtom);
  const [, setNotificationFilterInfo] = useAtom(notificationEnumAtom);
  const [, setPurchaseAccompanyingServicesFilterInfo] = useAtom(purchaseAccompanyingServicesEnumAtom);
  const [, setCapitalCounselServiceFilterInfo] = useAtom(capitalCounselServicesEnumAtom);
  const [, setDashboardFilterInfo] = useAtom(dashboardFilterEnumAtom);

  const getMetaInfo = async () => {
    try {
      const response = await getMeta();
      const res = response.data;
      setBannerFilterInfo(res.bannersFilterInfoResponse);
      setCapitalCounselServiceFilterInfo(res.capitalCounselServicesFilterInfoResponse);
      setDashboardFilterInfo(res.dashboardFilterResponse);
      setJobFilterInfo(res.jobFilterInfoResponse);
      setLicenseFilterInfo(res.licenseFilterInfoResponse);
      setMemberFilterInfo(res.membersAdminFilterInfoResponse);
      setNotificationFilterInfo(res.notificationFilterResponse);
      setOneStopServicesFilterInfo(res.oneStopServicesFilterInfoResponse);
      setProductInquiryFilterInfo(res.productInquiryFilterInfoResponse);
      setproductPurchaseManageFilterInfo(res.productPurchaseManageFilterResponse);
      setProductFilterInfo(res.productsFilterInfoResponse);
      setPurchaseAccompanyingServicesFilterInfo(res.purchaseAccompanyingServicesFilterResponse);
      setTransferAgencyServicesFilterInfo(res.transferAgencyServicesFilterResponse);
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  useQuery(['get-meta'], getMetaInfo, {
    staleTime: 600000,
  });

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleMenuClick = ({ key, keyPath }: { key: string; keyPath: string[] }) => {
    const parentKey = keyPath[keyPath.length - 1];
    const selectedItem = items.find((item) => item.key === key || (item.children && item.key === parentKey));

    if (selectedItem) {
      if (selectedItem.children) {
        const selectedSubItem = selectedItem.children.find((item) => item.key === key);
        if (selectedSubItem) {
          navigate(selectedSubItem.link);
        }
      } else if (selectedItem.link) {
        navigate(selectedItem.link);
      }
    }
  };

  useEffect(() => {
    getMemberData();
  }, []);

  const getMemberData = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      setMemberData(token);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh', minWidth: '756px' }}>
      <Sider
        width={220}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          background: '#ffffff',
        }}
      >
        <Link to="/">
          <div className="demo-logo-vertical flex justify-center items-center h-[60px] cursor-pointer">
            <SvgLogo color={'#1E42A6'}></SvgLogo>
            <span className="font-bold ml-3 text-lg">관리자센터</span>
          </div>
        </Link>
        <Menu defaultSelectedKeys={['1']} mode="inline" items={items} onClick={handleMenuClick}></Menu>
      </Sider>
      <Layout style={{ marginLeft: 220 }}>
        <HeaderComponent></HeaderComponent>
        <Content
          style={{
            margin: '16px',
            padding: 24,
            minHeight: 360,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            overflow: 'initial',
          }}
        >
          <Outlet />
        </Content>
        <Footer style={{ textAlign: 'center' }}>©{new Date().getFullYear()}직트럭</Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
