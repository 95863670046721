import { Button, Modal, Select, Table, TableColumnsType, message } from 'antd';
import React, { useEffect, useState } from 'react';

import { deleteLicense, patchLicense, patchLicenseMemo } from '@/api/public';
import BasicPopup from '@/components/Common/BasicPopup';
import SortingButton from '@/components/Common/SortingButton';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { licenseEnumAtom } from '@/store/license';
import { convertToSelectOptions } from '@/utils/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface LicenseResponseDataProps {
  selectedFilterValue: SelectedLicenseFilterValue;
  data: LicenseResponse | null;
  currentPage: number;
  getLicenseData: () => void;
  ascending: boolean;
  updateAscending: () => void;
}

interface DataType {
  carNumber?: string;
  certificationImage?: string;
  fee?: number;
  id: number;
  key: number;
  index: number;
  insuranceRate?: string;
  licenseImage?: string;
  licenseSalesType?: string;
  licenseType?: string;
  locate?: string;
  price?: number | string;
  status?: EnumPresenter;
  tons?: string | number;
  useClassification?: string;
  year?: number | string;
  licenseCounselStatus?: EnumPresenter;
  productNumber?: string;
  memo?: string;
}

interface LicenseCounselStatusModifyRequest {
  id: string;
  licenseCounselStatus: string;
}

interface LicenseStatusModifyRequest {
  id: string;
  licenseStatus: string;
}

const LicenseSearchResult = ({
  selectedFilterValue,
  data,
  currentPage,
  getLicenseData,
  ascending,
  updateAscending,
}: LicenseResponseDataProps) => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isMemoModalVisible, setIsMemoModalVisible] = useState(false);
  const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(true);
  const [memoContent, setMemoContent] = useState('');
  const [selectedMemoId, setSelectedMemoId] = useState(0);
  const [licenseFilterInfoType] = useAtom(licenseEnumAtom);
  const convertLicenseFilterInfoTypeToSelectOptions = (licenseFilterInfo: LicenseFilterInfoType) => {
    return {
      licenseCounselStatus: convertToSelectOptions(licenseFilterInfo.licenseCounselStatus),
    };
  };
  const selectOptions = convertLicenseFilterInfoTypeToSelectOptions(licenseFilterInfoType);
  const [basicPopupData, setBasicPopupData] = useState({
    title: '정말 삭제하시겠어요?',
    handleOk: () => onClickDelete(),
    handleCancel: () => setIsOpenPopup(false),
  });

  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },
    {
      title: '판매자',
      dataIndex: 'sellerName',
    },
    {
      title: '전화번호',
      dataIndex: 'phoneNumber',
    },
    {
      title: '제시번호',
      dataIndex: 'productNumber',
    },
    {
      title: '톤수',
      dataIndex: 'tons',
    },
    {
      title: '연식',
      dataIndex: 'year',
    },
    {
      title: '번호판 종류',
      dataIndex: 'licenseType',
    },
    {
      title: '거래방식',
      dataIndex: 'licenseSalesType',
    },
    {
      title: '번호판 용도',
      dataIndex: 'useClassification',
    },
    {
      title: '지역',
      dataIndex: 'locate',
    },

    {
      title: '가격',
      dataIndex: 'price',
    },
    {
      title: '상태',
      dataIndex: 'status',
      render: (text, record) => record.status?.desc,
    },
    {
      title: <SortingButton title="등록일자" isAscending={ascending} onclick={updateAscending} />,
      dataIndex: 'createdDate',
    },
    {
      title: '상담 상태',
      dataIndex: 'licenseCounselStatus',
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
          },
        };
      },
      render: (text, record) => (
        <Select
          value={record.licenseCounselStatus?.desc}
          options={selectOptions.licenseCounselStatus}
          style={{ width: 100 }}
          onChange={(value) => handleCounselStatusChange(value, record)}
        ></Select>
      ),
    },
    {
      title: '노출등록',
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
          },
        };
      },
      render: (text, record) => (
        <>
          <Button
            className="font-bold mr-3 overflow-hidden whitespace-no-wrap text-ellipsis break-all line-clamp-1"
            style={{
              maxWidth: '100px',
              maxHeight: '100px',
            }}
            disabled={isDisable(record)}
            onClick={() => handleStatusChange(record)}
          >
            판매 노출
          </Button>
        </>
      ),
    },
    {
      title: '메모',
      dataIndex: 'memo',
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
          },
        };
      },
      render: (text, record) => (
        <>
          {record.memo ? (
            <>
              <p
                className="font-bold mr-3 overflow-hidden whitespace-no-wrap text-ellipsis break-all"
                style={{
                  maxWidth: '500px',
                  maxHeight: '200px',
                }}
              >
                {record.memo}
              </p>

              <Button onClick={() => handleMemoButtonClick(record)}>수정</Button>
            </>
          ) : (
            <Button onClick={() => handleMemoButtonClick(record)}>작성</Button>
          )}
        </>
      ),
    },
  ];

  const handleMemoButtonClick = (licenseInfo: any) => {
    setIsMemoModalVisible(true);
    setSelectedMemoId(licenseInfo.id);
    setIsDisabledEditBtn(true);
    setMemoContent(licenseInfo.memo);
  };

  const onChangeMemo = (memo: string) => {
    setMemoContent(memo);
    setIsDisabledEditBtn(false);
  };

  const isDisable = (record: DataType) => {
    return !(record.status?.code == 'BEFORE_SALE' && record.licenseCounselStatus?.code == 'COUNSEL_COMPLETED');
  };

  const updatedTableData: DataType[] =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      sellerName: item.seller?.name || '-',
      phoneNumber: item.seller?.phoneNumber || '-',
      tons: item.tons || '-',
      year: item.year || '-',
      licenseType: item.licenseType?.desc || '-',
      licenseSalesType: item.licenseSalesType?.desc || '-',
      useClassification: item.useClassification?.desc || '-',
      locate: item.locate?.desc || '-',
      price: item.price || '-',
      status: item.status,
      createdDate: item.createdDate || '-',
      licenseCounselStatus: item.licenseCounselStatus,
      productNumber: item.productNumber,
      memo: item.memo,
    })) || [];

  const handleStatusChange = (record: DataType) => {
    const request = {
      id: String(record.id),
      licenseStatus: 'SALE',
    };
    setBasicPopupData({
      title: '판매 노출 시키시겠어요?',
      handleOk: () => updateStatus(request),
      handleCancel: () => setIsOpenPopup(false),
    });
    setIsOpenPopup(true);
  };

  const updateStatus = (request: LicenseStatusModifyRequest) => {
    patchLicenseStatusMutation.mutate(request);
  };

  const patchLicenseStatusMutation = useMutation(
    (request: LicenseStatusModifyRequest) =>
      patchLicense(String(request.id), {
        licenseStatus: request.licenseStatus,
      }),
    {
      onSuccess: () => {
        message.success('판매 노출 되었습니다.', 2);
        getLicenseData();
      },
      onError: () => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
      onSettled: () => {
        setIsOpenPopup(false);
      },
    },
  );

  const handleCounselStatusChange = (value: string, record: DataType) => {
    const request = {
      id: String(record.id),
      licenseCounselStatus: value,
    };

    setBasicPopupData({
      title: '상담 상태를 변경하시겠어요?',
      handleOk: () => updateCounselStatus(request),
      handleCancel: () => setIsOpenPopup(false),
    });
    setIsOpenPopup(true);
  };

  const updateCounselStatus = (request: LicenseCounselStatusModifyRequest) => {
    patchLicenseMutation.mutate(request);
  };

  const patchLicenseMutation = useMutation(
    (request: LicenseCounselStatusModifyRequest) =>
      patchLicense(String(request.id), {
        licenseCounselStatus: request.licenseCounselStatus,
      }),
    {
      onSuccess: () => {
        message.success('상담 상태가 변경되었습니다.', 2);
        getLicenseData();
      },
      onError: () => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
      onSettled: () => {
        setIsOpenPopup(false);
      },
    },
  );

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleMemoSave = () => {
    handleMemoModalClose();
    const request = {
      memo: memoContent,
    };
    // mutate
    patchLicenseMemoMutation.mutate(request);
  };

  const patchLicenseMemoMutation = useMutation(
    (requestData: { memo: string }) => patchLicenseMemo(String(selectedMemoId), requestData),
    {
      onSuccess: () => {
        message.success('메모가 수정되었습니다..', 2);
        setIsDisabledEditBtn(true);
        getLicenseData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const rowProps = (record: DataType) => ({
    onClick: () => {
      navigate(`/license-plates/${record.id}`, {
        state: { licenseData: data?.data?.filter((item, index) => item.id === record.id)[0] },
      });
    },
  });
  const handleMemoModalClose = () => {
    setIsMemoModalVisible(false);
    setMemoContent('');
  };

  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onClickDelete = () => {
    setIsOpenPopup(false);

    if (selectedRowKeys.length > 0) {
      const [firstSelectedId] = selectedRowKeys;
      mutate(String(firstSelectedId));
    } else {
      message.error('번호판이 선택되지 않았어요. 삭제할 번호판을 선택해주세요.');
    }
  };

  const { mutate } = useMutation(deleteLicense, {
    onSuccess: () => {
      message.success('삭제되었습니다.', 2);
      getLicenseData();
    },
    onError: (error: AxiosError) => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const hasSelected = selectedRowKeys.length > 0;

  const showConfirm = () => {
    setBasicPopupData({
      title: '정말 삭제하시겠어요?',
      handleOk: () => onClickDelete(),
      handleCancel: () => setIsOpenPopup(false),
    });
    setIsOpenPopup(true);
  };

  const onMoveRegisterPage = () => {
    navigate('/license-plates/form');
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 번호판 문의</span> {data?.totalElements}건
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}대 선택됨` : ''}</span>
          </div>
          <div>
            <Button className="mr-2" onClick={onMoveRegisterPage} style={{ borderColor: '#1890ff', color: '#1890ff' }}>
              등록
            </Button>
            <Button danger onClick={showConfirm}>
              삭제
            </Button>
          </div>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          onRow={rowProps}
          pagination={false}
        />
      </div>
      <Modal
        title="메모 보기"
        open={isMemoModalVisible}
        onCancel={handleMemoModalClose}
        footer={[
          <Button key="close" onClick={handleMemoModalClose}>
            닫기
          </Button>,
          <Button
            key="save"
            type="primary"
            className="bg-[#1890ff] mx-2"
            onClick={handleMemoSave}
            disabled={isDisabledEditBtn}
          >
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={memoContent}
          onChange={(e) => onChangeMemo(e.target.value)}
          placeholder="메모 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>
      <BasicPopup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title={basicPopupData.title}
        content=""
        handleOk={basicPopupData.handleOk}
        handleCancel={basicPopupData.handleCancel}
      ></BasicPopup>
    </>
  );
};

export default LicenseSearchResult;
