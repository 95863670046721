import '@/css/globalStyle.css';
import '@/css/tailwind.css';
import { ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import ScrollToTop from '@/components/Common/ScrollToTop';
import locale from 'antd/locale/ko_KR';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { Provider } from 'jotai';
import React from 'react';
import { createRoot } from 'react-dom/client';
import MainLayout from './components/Layout/MainLayout';
import NotificationReservationForm from './components/Notification/NotificationReservationForm';
import AdminMemberList from './pages/AdminMember/AdminMemberList';
import BannerList from './pages/Banner/BannerList';
import BannerRegister from './pages/Banner/BannerRegisterUpdate';
import CapitalCounselServicesList from './pages/CapitalCounselServices/CapitalCounselServicesList';
import ContractsList from './pages/Contracts/ContractsList';
import AppDownloadCountList from './pages/Dashboard/AppDownloadCountList';
import Dashboard from './pages/Dashboard/Dashboard';
import DelayProductsList from './pages/DelayProducts/DelayProductsList';
import DevelopDataManagement from './pages/DevelopDataManagement/DevelopDataManagement';
import NotFoundPage from './pages/Error/NotFoundPage';
import JobList from './pages/Job/JobList';
import JobRegisterUpdate from './pages/Job/JobRegisterUpdate';
import License from './pages/License/License';
import LicenseDetail from './pages/License/LicenseDetail';
import LicenseInquiry from './pages/License/LicenseInquiry';
import Login from './pages/Login/Login';
import MemberList from './pages/Member/MemberList';
import NoticeList from './pages/Notice/NoticeList';
import NoticeRegisterUpdate from './pages/Notice/NoticeRegisterUpdate';
import NotificationList from './pages/Notification/NotificationList';
import OneStopServicesListAll from './pages/OneStopServices/OneStopServicesListAll';
import PricesList from './pages/Prices/PricesList';
import ProductInquiryListAll from './pages/ProductInquiry/ProductInquiryListAll';
import PurchaseAccompanyingServicesList from './pages/PurchaseAccompanyingServices/PurchaseAccompanyingServicesList';
import SubsidyForFuelStandardList from './pages/SubsidyForFuelStandard/SubsidyForFuelStandardList';
import TermsManagement from './pages/Terms/TermsMangement';
import TermsRegisterUpdate from './pages/Terms/TermsRegisterUpdate';
import TransferAgencyServicesList from './pages/TransferAgencyServices/TransferAgencyServicesList';
import AssuranceDetail from './pages/Vehicles/AssuranceDetail';
import AssuranceList from './pages/Vehicles/AssuranceList';
import AssuranceRegister from './pages/Vehicles/AssuranceRegister';
import ConsignmentDetail from './pages/Vehicles/ConsignmentDetail';
import ConsignmentList from './pages/Vehicles/ConsignmentList';
import ConsignmentRegister from './pages/Vehicles/ConsignmentRegister';
import DirectDetail from './pages/Vehicles/DirectDetail';
import DirectList from './pages/Vehicles/DirectList';
import ModelManagement from './pages/Vehicles/ModelManagement';
import SpeedDetail from './pages/Vehicles/SpeedDetail';
import SpeedList from './pages/Vehicles/SpeedList';
import TheunbanDetail from './pages/Vehicles/TheunbanDetail';
import TheunbanList from './pages/Vehicles/TheunbanList';
import VehiclesAllDetail from './pages/Vehicles/VehiclesAllDetail';
import VehiclesListAll from './pages/Vehicles/VehiclesListAll';

dayjs.locale('ko');

type ProvidersProps = {
  children: React.ReactNode;
};

const queryClient = new QueryClient();

const Providers: React.FC<ProvidersProps> = ({ children }) => (
  <ConfigProvider
    locale={locale}
    theme={{
      token: {
        colorPrimary: '#1890ff',
      },
      components: {
        Button: {
          colorPrimary: '#1890ff',
        },
      },
    }}
  >
    <QueryClientProvider client={queryClient}>
      <Provider>{children}</Provider>
    </QueryClientProvider>
  </ConfigProvider>
);

const ProtectedRoute: React.FC<ProvidersProps> = ({ children }) => {
  const isAuthenticated = Boolean(localStorage.getItem('token'));
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;
};

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Providers>
        <Routes>
          <Route element={<MainLayout />}>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/members"
              element={
                <ProtectedRoute>
                  <MemberList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/all"
              element={
                <ProtectedRoute>
                  <VehiclesListAll />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/all/:id"
              element={
                <ProtectedRoute>
                  <VehiclesAllDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/direct"
              element={
                <ProtectedRoute>
                  <DirectList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/direct/:id"
              element={
                <ProtectedRoute>
                  <DirectDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/assurance"
              element={
                <ProtectedRoute>
                  <AssuranceList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/assurance/:id"
              element={
                <ProtectedRoute>
                  <AssuranceDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/assurance/form"
              element={
                <ProtectedRoute>
                  <AssuranceRegister />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/consignment"
              element={
                <ProtectedRoute>
                  <ConsignmentList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/consignment/:id"
              element={
                <ProtectedRoute>
                  <ConsignmentDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/consignment/form"
              element={
                <ProtectedRoute>
                  <ConsignmentRegister />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/speed"
              element={
                <ProtectedRoute>
                  <SpeedList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/speed/:id"
              element={
                <ProtectedRoute>
                  <SpeedDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/delay"
              element={
                <ProtectedRoute>
                  <DelayProductsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/theunban"
              element={
                <ProtectedRoute>
                  <TheunbanList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/theunban/:id"
              element={
                <ProtectedRoute>
                  <TheunbanDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/model"
              element={
                <ProtectedRoute>
                  <ModelManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/license-plates"
              element={
                <ProtectedRoute>
                  <License />
                </ProtectedRoute>
              }
            />
            <Route
              path="/license-plates/form"
              element={
                <ProtectedRoute>
                  <LicenseDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/license-plates/:id"
              element={
                <ProtectedRoute>
                  <LicenseDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/one-stop-services"
              element={
                <ProtectedRoute>
                  <OneStopServicesListAll />
                </ProtectedRoute>
              }
            />
            <Route
              path="product-inquiry"
              element={
                <ProtectedRoute>
                  <ProductInquiryListAll />
                </ProtectedRoute>
              }
            />
            <Route
              path="/license-plates/inquiry"
              element={
                <ProtectedRoute>
                  <LicenseInquiry />
                </ProtectedRoute>
              }
            />
            <Route
              path="/banner"
              element={
                <ProtectedRoute>
                  <BannerList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/banner/:id"
              element={
                <ProtectedRoute>
                  <BannerRegister />
                </ProtectedRoute>
              }
            />
            <Route
              path="/banner/form"
              element={
                <ProtectedRoute>
                  <BannerRegister />
                </ProtectedRoute>
              }
            />
            <Route
              path="/job"
              element={
                <ProtectedRoute>
                  <JobList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/job/:id"
              element={
                <ProtectedRoute>
                  <JobRegisterUpdate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/job/form"
              element={
                <ProtectedRoute>
                  <JobRegisterUpdate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/price-management"
              element={
                <ProtectedRoute>
                  <PricesList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notification"
              element={
                <ProtectedRoute>
                  <NotificationList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notification/reservation/form"
              element={
                <ProtectedRoute>
                  <NotificationReservationForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/transfer-agency-services"
              element={
                <ProtectedRoute>
                  <TransferAgencyServicesList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/purchase-accompanying-services"
              element={
                <ProtectedRoute>
                  <PurchaseAccompanyingServicesList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin-accounts"
              element={
                <ProtectedRoute>
                  <AdminMemberList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/contracts"
              element={
                <ProtectedRoute>
                  <ContractsList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/app-download-count"
              element={
                <ProtectedRoute>
                  <AppDownloadCountList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notice"
              element={
                <ProtectedRoute>
                  <NoticeList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notice/:id"
              element={
                <ProtectedRoute>
                  <NoticeRegisterUpdate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notice/form"
              element={
                <ProtectedRoute>
                  <NoticeRegisterUpdate />
                </ProtectedRoute>
              }
            />

            <Route
              path="/develop-data-management"
              element={
                <ProtectedRoute>
                  <DevelopDataManagement />
                </ProtectedRoute>
              }
            />

            <Route
              path="/capital-counsel-services"
              element={
                <ProtectedRoute>
                  <CapitalCounselServicesList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/subsidy"
              element={
                <ProtectedRoute>
                  <SubsidyForFuelStandardList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/terms-management"
              element={
                <ProtectedRoute>
                  <TermsManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/terms-management/form"
              element={
                <ProtectedRoute>
                  <TermsRegisterUpdate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/terms-management/:id"
              element={
                <ProtectedRoute>
                  <TermsRegisterUpdate />
                </ProtectedRoute>
              }
            />

            <Route path="/*" element={<NotFoundPage />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </Providers>
    </BrowserRouter>
  );
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement || document.createElement('div'));
root.render(<App />);
