import { getDashboardChart, getStatisticsReport } from '@/api/public';
import { dashboardFilterEnumAtom } from '@/store/dashboard';
import { convertToSelectOptions } from '@/utils/common';
import { DatePicker, Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import DualAxesLineChart from '../Common/Chart/LineChart';

const { RangePicker } = DatePicker;
dayjs.extend(weekOfYear);

const StatisticsReportSearchResult = () => {
  const [dashboardFilterEnum] = useAtom(dashboardFilterEnumAtom);
  const [searchStatisticsReportSearchResultData, setSearchStatisticsReportSearchResultData] =
    useState<StatisticsReportResponse | null>(null);

  const [chartData, setChartData] = useState<DashboardChartResponse[]>();
  const [periodOptions, setPeriodOptions] = useState<DefaultOptionType[]>();

  const [selectedChartFilter, setSelectedChartFilter] = useState({
    period: '',
    startDate: '',
    endDate: '',
  });

  const [requestParams, setRequestParams] = useState<DashboardChartRequestParams>({
    period: '',
    startDate: String(dayjs().startOf('year')),
    endDate: String(dayjs(new Date())),
    year: null,
    startWeek: null,
    endWeek: null,
    startMonth: null,
    endMonth: null,
  });

  const handleChangePeriod = (data: string) => {
    setSelectedChartFilter((prevOptions) => ({
      period: data,
      startDate: '',
      endDate: '',
      year: '',
      startWeek: null,
      endWeek: null,
      startMonth: null,
      endMonth: null,
    }));
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    if (Array.isArray(dates) && dates.length === 2) {
      const [startDate, endDate] = dates;
      setSelectedChartFilter((prevOptions) => ({
        ...prevOptions,
        startDate: startDate,
        endDate: endDate,
      }));
    }
  };

  const isValidateRequest = () => {
    switch (requestParams.period) {
      case 'DAILY':
        return requestParams.startDate !== '' && requestParams.endDate !== '';
      case 'WEEKLY':
        return requestParams.startWeek !== '' && requestParams.endWeek !== '';
      case 'MONTHLY':
        return requestParams.startMonth !== '' && requestParams.endMonth !== '';
    }
  };

  useQuery(['get-statistics-report', requestParams], () => getStatisticsReport(requestParams), {
    enabled: !!requestParams.period && isValidateRequest(),
    onSuccess: (data) => {
      setSearchStatisticsReportSearchResultData(data);
    },
  });

  useQuery(['get-dashboard-chart', requestParams], () => getDashboardChart(requestParams), {
    enabled: !!requestParams.period && isValidateRequest(),
    onSuccess: (data) => {
      setChartData(data);
    },
  });

  useEffect(() => {
    switch (selectedChartFilter.period) {
      case 'DAILY':
        setRequestParams({
          period: selectedChartFilter.period,
          startDate: selectedChartFilter.startDate ? dayjs(selectedChartFilter.startDate).format('YYYY-MM-DD') : '',
          endDate: selectedChartFilter.endDate ? dayjs(selectedChartFilter.endDate).format('YYYY-MM-DD') : '',
        });
        break;
      case 'WEEKLY':
        setRequestParams({
          period: selectedChartFilter.period,
          year: selectedChartFilter.startDate ? String(dayjs(selectedChartFilter.startDate).year()) : '',
          startWeek: selectedChartFilter.startDate ? String(dayjs(selectedChartFilter.startDate).week()) : '',
          endWeek: selectedChartFilter.endDate ? String(dayjs(selectedChartFilter.endDate).week()) : '',
        });
        break;
      case 'MONTHLY':
        setRequestParams({
          period: selectedChartFilter.period,
          startMonth: selectedChartFilter.startDate ? dayjs(selectedChartFilter.startDate).format('YYYY-MM') : '',
          endMonth: selectedChartFilter.endDate ? dayjs(selectedChartFilter.endDate).format('YYYY-MM') : '',
        });
    }
  }, [selectedChartFilter]);

  useEffect(() => {
    setPeriodOptions(convertToSelectOptions(dashboardFilterEnum.period));
    setSelectedChartFilter({
      period: convertToSelectOptions(dashboardFilterEnum.period)[0]?.value,
      startDate: String(dayjs('2023-12-01')),
      endDate: String(dayjs(new Date()).format('YYYY-MM-DD')),
    });
  }, [dashboardFilterEnum]);

  return (
    <div className="py-2 px-4">
      <h1 className="font-bold text-lg mb-4">상태지표</h1>
      <div className="flex">
        <div className="flex-1">
          <span className="mr-2">구별</span>
          <Select
            className="mr-4 my-2"
            style={{ width: 120 }}
            placeholder="급여 방식"
            optionFilterProp="children"
            options={periodOptions || []}
            value={selectedChartFilter.period}
            onChange={(value) => handleChangePeriod(value)}
          />
          <span className="mr-2">검색구간</span>

          {selectedChartFilter.period === 'DAILY' && (
            <RangePicker
              className="min-w-[400px]"
              value={[
                selectedChartFilter.startDate ? dayjs(selectedChartFilter.startDate) : null,
                selectedChartFilter.endDate ? dayjs(selectedChartFilter.endDate) : null,
              ]}
              onChange={handleDateRangeChange}
              placeholder={['시작 날짜', '종료 날짜']}
            />
          )}
          {selectedChartFilter.period === 'WEEKLY' && (
            <RangePicker
              picker="week"
              className="min-w-[400px]"
              value={[
                selectedChartFilter.startDate ? dayjs(selectedChartFilter.startDate) : null,
                selectedChartFilter.endDate ? dayjs(selectedChartFilter.endDate) : null,
              ]}
              onChange={handleDateRangeChange}
              placeholder={['시작 날짜', '종료 날짜']}
            />
          )}
          {selectedChartFilter.period === 'MONTHLY' && (
            <RangePicker
              picker="month"
              className="min-w-[400px]"
              value={[
                selectedChartFilter.startDate ? dayjs(selectedChartFilter.startDate) : null,
                selectedChartFilter.endDate ? dayjs(selectedChartFilter.endDate) : null,
              ]}
              onChange={handleDateRangeChange}
              placeholder={['시작 날짜', '종료 날짜']}
            />
          )}

          <DualAxesLineChart chartData={chartData}></DualAxesLineChart>
        </div>

        <div className="flex items-center">
          <div className="p-2 rounded-lg border border-gray-300 w-52">
            <div className="flex justify-between mb-1">
              <span>신규 누적 다운로드 수</span>
              <span className="font-bold">{searchStatisticsReportSearchResultData?.totalDownloadAppCount}</span>
            </div>
            <div className="flex justify-between mb-1">
              <span>누적 회원수</span>
              <span className="font-bold">{searchStatisticsReportSearchResultData?.totalMemberCount}</span>
            </div>
            <div className="flex justify-between mb-1">
              <span>회원 가입 비율</span>
              <span className="font-bold">{searchStatisticsReportSearchResultData?.joinRate}%</span>
            </div>
            <div className="flex justify-between mb-1">
              <span>매물 등록 대수(대)</span>
              <span className="font-bold">{searchStatisticsReportSearchResultData?.registerProductCount}</span>
            </div>
            <div className="flex justify-between mb-1">
              <span>매물 등록 비율</span>
              <span className="font-bold">{searchStatisticsReportSearchResultData?.registerProductRate}%</span>
            </div>
            <div className="flex justify-between mb-1">
              <span>거래 완료 대수(대)</span>
              <span className="font-bold">{searchStatisticsReportSearchResultData?.completedProductCount}</span>
            </div>
            <div className="flex justify-between">
              <span>거래 완료 비율</span>
              <span className="font-bold">{searchStatisticsReportSearchResultData?.completedProductRate}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsReportSearchResult;
