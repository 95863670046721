import { postSubsidyForFuelStandard } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE, NO_REQUIRED_VALUE_ERROR_MESSAGE } from '@/const/errorMessage';
import { Button, DatePicker, DatePickerProps, Input, message, Modal, Table, TableColumnsType } from 'antd';
import ko from 'antd/es/date-picker/locale/ko_KR';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

interface DataType {
  id: number;
  key: number;
  subsidy: number;
  oneTonLimit: number;
  threeTonLimit: number;
  fiveTonLimit: number;
  eightTonLimit: number;
  tenTonLimit: number;
  twelveTonLimit: number;
  twelveTonOverLimit: number;
  baseDay: string;
}

const SubsidyForFuelStandardSearchResult = ({
  data,
  getSubsidyForFuelStandardData,
}: SubsidyForFuelStandardResponseProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const updatedTableData =
    data?.data?.map((item) => ({
      id: item.id,
      key: item.id,
      subsidy: item.subsidy,
      oneTonLimit: item.oneTonLimit,
      threeTonLimit: item.threeTonLimit,
      fiveTonLimit: item.fiveTonLimit,
      eightTonLimit: item.eightTonLimit,
      tenTonLimit: item.tenTonLimit,
      twelveTonLimit: item.twelveTonLimit,
      twelveTonOverLimit: item.twelveTonOverLimit,
      baseDay: item.baseDay,
    })) || [];
  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);

  useEffect(() => {
    setRequest((prevOptions) => ({
      baseDay: null,
      subsidy: null,
      oneTonLimit: data ? data.data[0].oneTonLimit : null,
      threeTonLimit: data ? data.data[0].threeTonLimit : null,
      fiveTonLimit: data ? data.data[0].fiveTonLimit : null,
      eightTonLimit: data ? data.data[0].eightTonLimit : null,
      tenTonLimit: data ? data.data[0].tenTonLimit : null,
      twelveTonLimit: data ? data.data[0].twelveTonLimit : null,
      twelveTonOverLimit: data ? data.data[0].twelveTonOverLimit : null,
    }));
    setTableData(updatedTableData);
  }, [data]);

  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'id',
    },
    {
      title: '기준일자',
      dataIndex: 'baseDay',
    },
    {
      title: '리터당 보조금(원)',
      dataIndex: 'subsidy',
    },
    {
      title: '1톤 이하 보조금 한도(L)',
      dataIndex: 'oneTonLimit',
    },
    {
      title: '3톤 이하 보조금 한도(L)',
      dataIndex: 'threeTonLimit',
    },
    {
      title: '5톤 이하 보조금 한도(L)',
      dataIndex: 'fiveTonLimit',
    },
    {
      title: '8톤 이하 보조금 한도(L)',
      dataIndex: 'eightTonLimit',
    },
    {
      title: '10톤 이하 보조금 한도(L)',
      dataIndex: 'tenTonLimit',
    },
    {
      title: '12톤 이하 보조금 한도(L)',
      dataIndex: 'twelveTonLimit',
    },
    {
      title: '12톤 초과 보조금 한도(L)',
      dataIndex: 'twelveTonOverLimit',
    },
  ];

  const onClickRegisterButton = () => {
    setIsOpenModal(true);
  };
  const onCloseModal = () => {
    setIsOpenModal(false);
    setRequest(() => ({
      subsidy: null,
      oneTonLimit: null,
      threeTonLimit: null,
      fiveTonLimit: null,
      eightTonLimit: null,
      tenTonLimit: null,
      twelveTonLimit: null,
      twelveTonOverLimit: null,
      baseDay: null,
    }));
  };
  const [request, setRequest] = useState<SubsidyForFuelStandardRegisterRequest>({
    subsidy: null,
    oneTonLimit: null,
    threeTonLimit: null,
    fiveTonLimit: null,
    eightTonLimit: null,
    tenTonLimit: null,
    twelveTonLimit: null,
    twelveTonOverLimit: null,
    baseDay: null,
  });

  const onChange: DatePickerProps['onChange'] = (_, dateStr) => {
    setRequest((prevOptions) => ({
      ...prevOptions,
      baseDay: dateStr,
    }));
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRequest({ ...request, [name]: value });
  };
  const postSubsidyForFuelStandardMutation = useMutation(
    (requestData: SubsidyForFuelStandardRegisterRequest) => postSubsidyForFuelStandard(requestData),
    {
      onSuccess: () => {
        message.success('유류세 연동 보조금 기준이 등록되었습니다..', 2);
        setIsOpenModal(false);
        setRequest(() => ({
          subsidy: null,
          oneTonLimit: null,
          threeTonLimit: null,
          fiveTonLimit: null,
          eightTonLimit: null,
          tenTonLimit: null,
          twelveTonLimit: null,
          twelveTonOverLimit: null,
          baseDay: null,
        }));
        getSubsidyForFuelStandardData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );
  const onClickRegister = () => {
    if (
      request.baseDay === null ||
      request.subsidy === null ||
      request.oneTonLimit === null ||
      request.threeTonLimit === null ||
      request.fiveTonLimit === null ||
      request.eightTonLimit === null ||
      request.tenTonLimit === null ||
      request.twelveTonLimit === null ||
      request.twelveTonOverLimit === null
    ) {
      message.error(NO_REQUIRED_VALUE_ERROR_MESSAGE, 2);
    } else {
      postSubsidyForFuelStandardMutation.mutate(request);
    }
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-end" style={{ marginBottom: 16 }}>
          <div>
            <Button onClick={onClickRegisterButton}>등록</Button>
          </div>
        </div>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>

      <Modal title="유류세 연동 보조금 최신화" open={isOpenModal} footer={null} onCancel={onCloseModal} centered>
        <div style={{ textAlign: 'center' }}>
          <div className="flex items-center justify-center p-2">
            <span className="w-28">기준일자:</span>
            <DatePicker
              onChange={onChange}
              locale={ko}
              format="YYYY-MM-DD"
              style={{ width: '100%' }}
              value={request.baseDay ? dayjs(request.baseDay) : null}
            />
          </div>

          <div className="flex items-center justify-center p-2">
            <span className="w-28">리터당 보조금(원):</span>
            <Input
              placeholder="리터당 보조금(원)"
              name="subsidy"
              onChange={onChangeInput}
              value={request.subsidy ? request.subsidy : ''}
            />
          </div>

          <div className="flex items-center justify-center p-2">
            <span className="w-28">1톤 이하 보조금 한도(L): </span>
            <Input
              placeholder="1톤 이하 보조금 한도(L)"
              name="oneTonLimit"
              onChange={onChangeInput}
              value={request.oneTonLimit ? request.oneTonLimit : ''}
            />
          </div>

          <div className="flex items-center justify-center p-2">
            <span className="w-28">3톤 이하 보조금 한도(L): </span>
            <Input
              placeholder="3톤 이하 보조금 한도(L)"
              name="threeTonLimit"
              onChange={onChangeInput}
              value={request.threeTonLimit ? request.threeTonLimit : ''}
            />
          </div>
          <div className="flex items-center justify-center p-2">
            <span className="w-28">5톤 이하 보조금 한도(L): </span>
            <Input
              placeholder="5톤 이하 보조금 한도(L)"
              name="fiveTonLimit"
              onChange={onChangeInput}
              value={request.fiveTonLimit ? request.fiveTonLimit : ''}
            />
          </div>
          <div className="flex items-center justify-center p-2">
            <span className="w-28">8톤 이하 보조금 한도(L): </span>
            <Input
              placeholder="8톤 이하 보조금 한도(L)"
              name="eightTonLimit"
              onChange={onChangeInput}
              value={request.eightTonLimit ? request.eightTonLimit : ''}
            />
          </div>
          <div className="flex items-center justify-center p-2">
            <span className="w-28">10톤 이하 보조금 한도(L): </span>
            <Input
              placeholder="10톤 이하 보조금 한도(L)"
              name="tenTonLimit"
              onChange={onChangeInput}
              value={request.tenTonLimit ? request.tenTonLimit : ''}
            />
          </div>
          <div className="flex items-center justify-center p-2">
            <span className="w-28">12톤 이하 보조금 한도(L): </span>
            <Input
              placeholder="12톤 이하 보조금 한도(L)"
              name="twelveTonLimit"
              onChange={onChangeInput}
              value={request.twelveTonLimit ? request.twelveTonLimit : ''}
            />
          </div>
          <div className="flex items-center justify-center p-2">
            <span className="w-28">12톤 초과 보조금 한도(L): </span>
            <Input
              placeholder="12톤 초과 보조금 한도(L)"
              name="twelveTonOverLimit"
              onChange={onChangeInput}
              value={request.twelveTonOverLimit ? request.twelveTonOverLimit : ''}
            />
          </div>
          <div className="flex justify-end">
            <Button className="w-[90px]" onClick={() => onCloseModal()}>
              닫기
            </Button>
            <Button type="primary" className="w-[90px] bg-[#1890ff] mx-2" onClick={onClickRegister}>
              완료
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SubsidyForFuelStandardSearchResult;
