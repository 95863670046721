import {
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Image, Space } from 'antd';
import React, { useState } from 'react';
import DragAndDropWrapper from '../../Common/DragAndDropWrapper';
import DraggableUploadItem from './DraggableUploadItem';

const ProductImagesPreview = ({
  imagesList,
  setProductImagesList,
  setIsDisabledEditBtn,
  salesType,
  truckNumber,
  productImageData,
}: {
  imagesList: ProductImage[];
  setProductImagesList: React.Dispatch<React.SetStateAction<any>>;
  setIsDisabledEditBtn: React.Dispatch<React.SetStateAction<boolean>>;
  salesType?: string;
  truckNumber?: string;
  productImageData?: ProductImage[];
}) => {
  const [current, setCurrent] = useState(0);

  const onDownload = () => {
    const url = imagesList[current]?.value;
    const filename = truckNumber + '_' + imagesList[current]?.name + '.jpg';
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(blobUrl);
        link.remove();
      });
  };

  return (
    <div className="flex flex-wrap">
      <Image.PreviewGroup
        preview={{
          toolbarRender: (
            _,
            {
              transform: { scale },
              actions: { onActive, onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset },
            },
          ) => (
            <Space size={12} className="toolbar-wrapper">
              <LeftOutlined onClick={() => onActive?.(-1)} />
              <RightOutlined onClick={() => onActive?.(1)} />
              <DownloadOutlined onClick={onDownload} />
              <SwapOutlined rotate={90} onClick={onFlipY} />
              <SwapOutlined onClick={onFlipX} />
              <RotateLeftOutlined onClick={onRotateLeft} />
              <RotateRightOutlined onClick={onRotateRight} />
              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
              <UndoOutlined onClick={onReset} />
            </Space>
          ),
          onChange: (index) => {
            setCurrent(index);
          },
        }}
      >
        <div className="z-10 flex flex-wrap">
          <DragAndDropWrapper
            productImagesList={imagesList}
            setProductImagesList={setProductImagesList}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
            isDisabled={salesType === 'ALL'}
          >
            {imagesList?.map((item, index) => (
              <DraggableUploadItem
                key={`${item.id}_${item.value}_${imagesList.length}`}
                item={item}
                truckNumber={truckNumber}
                index={index}
                productImagesList={imagesList}
                setProductImagesList={setProductImagesList}
                setIsDisabledEditBtn={setIsDisabledEditBtn}
                salesType={salesType}
              />
            ))}
          </DragAndDropWrapper>
        </div>
        <div className="z-0 absolute flex flex-wrap">
          {productImageData?.map((item, index) => (
            <div className="flex flex-col text-center " key={index}>
              <div className="w-[198px] h-[132px] m-2">
                <div className="w-full h-full bg-[#DCDCDC]" />
              </div>

              <div className="mb-4 h-[16px] mt-8">{item?.name}</div>
            </div>
          ))}
        </div>
      </Image.PreviewGroup>
    </div>
  );
};

export default ProductImagesPreview;
