import { Button, Input, Modal, Select, Table, TableColumnsType, message } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';

import { deleteModels, patchModels, postModels } from '@/api/public';
import BasicPopup from '@/components/Common/BasicPopup';
import { productsEnumAtom } from '@/store/products';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';

interface ModelResponseDataProps {
  data: ModelResponse | null;
  getData: () => void;
}

const ModelList = ({ data, getData }: ModelResponseDataProps) => {
  const [productFilterInfo] = useAtom(productsEnumAtom);

  const [selectedManufacturerCategory, setSelectedManufacturerCategory] = useState<Option | null>();
  const [modelInput, setModelInput] = useState('');

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const columns: TableColumnsType<ModelResponseData> = useMemo(
    () => [
      { title: '제조사', dataIndex: 'manufacturerCategories' },
      { title: '모델명', dataIndex: 'model' },
    ],
    [],
  );

  const tableData = useMemo(
    () =>
      data?.data?.map((item) => ({
        id: item.id,
        key: item.id,
        manufacturerCategories: item.manufacturerCategories.name,
        model: item.name,
      })) || [],
    [data],
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowProps = (record: any) => ({
    onClick: () => {
      setIsEdit(true);
      setIsOpenModal(true);
      const convertValue: Option = {
        label: record.manufacturerCategories.toString(),
        value: record.id,
      };
      setSelectedManufacturerCategory(convertValue);
      setModelInput(record.model);
    },
  });

  const rowSelection: TableRowSelection<any> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const selectOptions = useMemo(
    () =>
      productFilterInfo.manufacturerCategoriesWithModels.map((item: ManufacturerCategoriesWithModels) => ({
        value: item.manufacturerCategories.id.toString(),
        label: item.manufacturerCategories.name,
      })),
    [productFilterInfo],
  );

  const postModelsMutation = useMutation(postModels, {
    onSuccess: (response) => {
      setIsOpenModal(false);
      message.success('모델이 등록되었습니다.', 2);
      getData();
      setSelectedRowKeys([]);
    },
    onError: (error: AxiosError) => {
      message.error(error.message, 2);
    },
  });

  const updateModelsMutation = useMutation(
    (requestData: any) => patchModels(Number(selectedManufacturerCategory?.value), requestData),
    {
      onSuccess: (response) => {
        setIsOpenModal(false);
        message.success('모델이 수정되었습니다.', 2);
        getData();
        setSelectedRowKeys([]);
      },
      onError: (error: AxiosError) => {
        message.error(error.message, 2);
      },
    },
  );

  const deleteModelMutation = useMutation(deleteModels, {
    onSuccess: () => {
      message.success('삭제되었습니다.', 2);
      getData();
      setSelectedRowKeys([]);
    },
    onError: (error: AxiosError) => {
      message.error(error.message, 5);
    },
  });

  const handleCloseModal = useCallback(() => {
    setIsOpenModal(false);
    setSelectedManufacturerCategory(null);
    setModelInput('');
    setIsEdit(false);
  }, []);

  const handleCompleteButtonClick = useCallback(() => {
    if (!isEdit) {
      postModelsMutation.mutate({
        manufacturerCategoriesId: selectedManufacturerCategory,
        modelName: modelInput,
      });
    } else {
      updateModelsMutation.mutate({ modelName: modelInput });
    }
  }, [isEdit, selectedManufacturerCategory, modelInput, postModelsMutation, updateModelsMutation]);

  const handleDelete = useCallback(() => {
    setIsOpenPopup(false);
    if (selectedRowKeys.length) {
      deleteModelMutation.mutate(String(selectedRowKeys[0]));
    } else {
      message.error('모델이 선택되지 않았어요. 삭제할 모델을 선택해주세요.');
    }
  }, [selectedRowKeys, deleteModelMutation]);

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 모델</span> {data?.totalElements}개
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}대 선택됨` : ''}</span>
          </div>
          <div>
            <Button
              className="mr-2"
              style={{ borderColor: '#1890ff', color: '#1890ff' }}
              onClick={() => setIsOpenModal(true)}
            >
              등록
            </Button>
            <Button danger onClick={() => setIsOpenPopup(true)}>
              삭제
            </Button>
          </div>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          onRow={rowProps}
          pagination={false}
        />
      </div>
      <BasicPopup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: 8 }} />}
        title="정말 모델을 삭제하시겠어요?"
        handleOk={handleDelete}
        handleCancel={() => setIsOpenPopup(false)}
      />

      <Modal
        title={<div style={{ textAlign: 'center', paddingBottom: '16px' }}>모델 {isEdit ? '수정' : '등록'}</div>}
        open={isOpenModal}
        onCancel={handleCloseModal}
        footer={null}
        centered
      >
        <div className="flex items-center justify-center mb-4 ">
          <span className="w-16">제조사:</span>
          <Select
            style={{ width: 200 }}
            placeholder="제조사"
            options={selectOptions}
            value={selectedManufacturerCategory}
            onChange={setSelectedManufacturerCategory}
            disabled={isEdit}
          />
        </div>
        <div className="flex items-center justify-center mb-4">
          <span className="w-16">모델:</span>
          <Input
            style={{ width: 200 }}
            placeholder="모델명"
            value={modelInput}
            onChange={(e) => setModelInput(e.target.value)}
          />
        </div>
        <div className="flex justify-end">
          <Button onClick={handleCloseModal}>닫기</Button>
          <Button
            type="primary"
            onClick={handleCompleteButtonClick}
            disabled={!selectedManufacturerCategory || !modelInput}
            loading={updateModelsMutation.isLoading || postModelsMutation.isLoading}
            className="ml-2"
          >
            완료
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModelList;
