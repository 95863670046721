import {
  getMemberByKeyword,
  patchProductPerchase,
  patchProductsDetail,
  postProductInvoicesForTheunban,
} from '@/api/public';
import {
  convertToSalesPeopleOptions,
  convertToSelectOptions,
  formatNumberWithComma,
  getFormatDate,
  getOnlyDate,
  isSpeed,
} from '@/utils/common';
import { Button, Col, Divider, Input, InputNumber, message, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import FileUploader from '@/components/Common/FileUploader';
import MultiFileUploader from '@/components/Common/MultiFileUploader';
import ProductImagesPreview from '@/components/Vehicles/Common/ProductImagesPreview';
import {
  COMMON_TOAST_ERROR_MESSAGE,
  NO_REQUIRED_VALUE_ERROR_MESSAGE,
  NOT_MATCH_OWNER_NAME,
} from '@/const/errorMessage';
import { ALL, ASSURANCE, CONSIGNMENT, NORMAL, SPEED } from '@/const/vehicles';
import { productPurchaseManageEnumAtom } from '@/store/productPurchaseManage';
import { productsEnumAtom } from '@/store/products';
import { DownloadOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface SelectedValue {
  seller: Member | null;
  truckNumber?: string | null;
  axis?: string | null;
  distance?: number | null;
  tons?: string | null;
  manufacturerCategories?: string | null;
  model?: string | null;
  status?: string | null;
  productsType?: string | null;
  salesType?: string | null;
  price?: number | null;
  actualSalePrice?: number | null;
  fuel?: string | null;
  transmission?: string;
  tireStatus?: string;
  garage?: string;
  power?: string | null;
  loaded?: string | null;
  loadedInnerLength?: number | null;
  loadedInnerArea?: number | null;
  loadedInnerHeight?: number | null;
  palletCount?: number | null;
  color?: string | null;
  isAccident?: boolean | null;
  accidentContents?: string | null;
  transportStartLocate?: string | null;
  transportEndLocate?: string | null;
  transportGoods?: string | null;
  salesPeople?: string | null;
  maintenance?: string | null;
  normalOption?: string | null;
  additionalOption?: string | null;
  breakOption?: string | null;
  detailContent?: string | null;
  productPurchaseManageStatus?: string | null;
  productPurchaseManageComment?: string;
  prices?: PricesWithStandard | null;
  buyerId?: string | null;
  buyer?: string | null;
  ownerName?: string;
}

const VehicleInfoContent = ({ salesType, data, updateData }: ProductDetailResponseDataProps) => {
  const navigate = useNavigate();
  const labelStyle: React.CSSProperties = { padding: '8px 0', fontWeight: '700' };
  const style: React.CSSProperties = { padding: '8px 0' };

  const [buyerList, setBuyerList] = useState<MemberTableDataType[]>([]);
  const [messageApi] = message.useMessage();
  const [productFilterEnum] = useAtom(productsEnumAtom);
  const [productPurchaseManageFilterEnum] = useAtom(productPurchaseManageEnumAtom);
  const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(true);
  const [isDisabledProductInvoicesSendBtn, setIsDisabledProductInvoicesSendBtn] = useState(true);
  const [isDisabledInput, setIsDisabledInput] = useState(false);
  const [certificateData, setCerticicateData] = useState({
    index: 0,
    id: 'certificateImageUrl',
    name: '차량등록증',
    value: data?.productsImage?.certificateImageUrl,
  });
  const [engineData, setEngineData] = useState({
    index: 11,
    id: 'engineVideoUrl',
    name: '엔진 영상',
    value: data?.productsImage?.engineVideoUrl,
  });
  const [saleInvoiceImageUrl, setSaleInvoiceImageUrl] = useState({
    index: 11,
    id: 'saleInvoiceImageUrl',
    name: '매매계약서',
    value: data?.productsImage?.saleInvoiceImageUrl,
  });
  const [taxImageUrl, setTaxImageUrl] = useState({
    index: 11,
    id: 'taxImageUrl',
    name: '세금 계약서',
    value: data?.productsImage?.taxImageUrl,
  });
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const error = (text: string) => {
    messageApi.open({
      type: 'error',
      content: `${text}`,
    });
  };

  useEffect(() => {
    if (saleInvoiceImageUrl.value && taxImageUrl.value) {
      setIsDisabledProductInvoicesSendBtn(false);
    }
  }, [saleInvoiceImageUrl, taxImageUrl]);

  const onClickPurchase = () => {
    setIsOpenPopup(true);
  };

  const handleCloseModal = () => {
    setIsOpenPopup(false);
  };

  const handleSearch = (value: string) => {
    if (value != '') {
      getMemberByKeywordData(value);
    }
  };

  const handleChange = (value: string) => {
    if (value) {
      setSelectedValue((prevOptions) => ({
        ...prevOptions,
        buyerId: value,
        buyer: value,
      }));
      setIsDisabledEditBtn(false);
    }
  };

  const onClickAssurancePurchaseBtn = () => {
    const request = {
      salesType: ASSURANCE,
    };
    patchProductPerchaseMutation.mutate(request);
    setIsOpenPopup(false);
  };

  const onClickConsignmentPurchaseBtn = () => {
    const request = {
      salesType: CONSIGNMENT,
    };
    patchProductPerchaseMutation.mutate(request);
    setIsOpenPopup(false);
  };

  const keyValueList: KeyValueListType = {
    frontSideImageUrl: '앞측면',
    backSideImageUrl: '후측면',
    frontImageUrl: '앞면',
    backImageUrl: '후면',
    tireImageUrl: '타이어',
    engineImageUrl: '엔진',
    insideImageUrl: '실내',
    dashboardImageUrl: '계기판',
    sheetImageUrl: '시트',
    optionImageUrl1: '옵션1',
    optionImageUrl2: '옵션2',
    optionImageUrl3: '옵션3',
    optionImageUrl4: '옵션4',
    optionImageUrl5: '옵션5',
    optionImageUrl6: '옵션6',
    optionImageUrl7: '옵션7',
  };

  const productImageData = data?.productsImage
    ? Object.entries(data?.productsImage)
        .filter(
          ([key]) =>
            key !== 'id' &&
            key !== 'certificateImageUrl' &&
            key !== 'engineVideoUrl' &&
            key !== 'saleInvoiceImageUrl' &&
            key !== 'taxImageUrl' &&
            key !== 'isSend',
        )
        .flatMap(([key, value], index) => {
          if (key === 'optionImageUrl' && Array.isArray(value)) {
            return value.map((item, idx) => ({
              id: `${key}${idx + 1}`,
              name: keyValueList[`${key}${idx + 1}`],
              value: item,
              index: index + idx,
            }));
          } else {
            return [{ id: key, name: keyValueList[key], value: value, index: index }];
          }
        })
    : [];

  const [productImagesList, setProductImagesList] = useState<ProductImage[]>([
    {
      id: 'frontSideImageUrl',
      name: '앞측면',
      value: data?.productsImage?.frontSideImageUrl || '',
      index: 0,
    },
    {
      id: 'backSideImageUrl',
      name: '후측면',
      value: data?.productsImage?.backSideImageUrl || '',
      index: 1,
    },
    {
      id: 'frontImageUrl',
      name: '앞면',
      value: data?.productsImage?.frontImageUrl || '',
      index: 2,
    },
    {
      id: 'backImageUrl',
      name: '후면',
      value: data?.productsImage?.backImageUrl || '',
      index: 3,
    },
    {
      id: 'tireImageUrl',
      name: '타이어',
      value: data?.productsImage?.tireImageUrl || '',
      index: 4,
    },
    {
      id: 'engineImageUrl',
      name: '엔진',
      value: data?.productsImage?.engineImageUrl || '',
      index: 5,
    },
    {
      id: 'insideImageUrl',
      name: '실내',
      value: data?.productsImage?.insideImageUrl || '',
      index: 6,
    },
    {
      id: 'dashboardImageUrl',
      name: '계기판',
      value: data?.productsImage?.dashboardImageUrl || '',
      index: 7,
    },
    {
      id: 'sheetImageUrl',
      name: '시트',
      value: data?.productsImage?.sheetImageUrl || '',
      index: 8,
    },
    {
      id: 'optionImageUrl1',
      name: '옵션1',
      value: data?.productsImage?.optionImageUrl?.[0] || '',
      index: 9,
    },
    {
      id: 'optionImageUrl2',
      name: '옵션2',
      value: data?.productsImage?.optionImageUrl?.[1] || '',
      index: 10,
    },
    {
      id: 'optionImageUrl3',
      name: '옵션3',
      value: data?.productsImage?.optionImageUrl?.[2] || '',
      index: 11,
    },
    {
      id: 'optionImageUrl4',
      name: '옵션4',
      value: data?.productsImage?.optionImageUrl?.[3] || '',
      index: 12,
    },
    {
      id: 'optionImageUrl5',
      name: '옵션5',
      value: data?.productsImage?.optionImageUrl?.[4] || '',
      index: 12,
    },
    {
      id: 'optionImageUrl6',
      name: '옵션6',
      value: data?.productsImage?.optionImageUrl?.[5] || '',
      index: 12,
    },
    {
      id: 'optionImageUrl7',
      name: '옵션7',
      value: data?.productsImage?.optionImageUrl?.[6] || '',
      index: 13,
    },
  ]);

  const convertManufacturerCategoriesToSelectOptions = (
    manufacturerCategoriesWithModels: ManufacturerCategoriesWithModels[],
  ): { value: string; label: string }[] => {
    return manufacturerCategoriesWithModels.map((item) => ({
      value: item.manufacturerCategories.id.toString(),
      label: item.manufacturerCategories.name,
    }));
  };

  const convertModelsToSelectOptions = (
    manufacturerCategoriesWithModels: ManufacturerCategoriesWithModels[],
    selectedManufacturerCategoryId: string,
  ): { value: string; label: string }[] => {
    const selectedManufacturerCategory = manufacturerCategoriesWithModels.find(
      (item) => item.manufacturerCategories.id.toString() === selectedManufacturerCategoryId,
    );
    return selectedManufacturerCategory
      ? selectedManufacturerCategory.model.map((model) => ({
          value: model.id.toString(),
          label: model.name,
        }))
      : [];
  };

  const convertProductsFilterInfoToSelectOptions = (
    productFilterEnum: ProductsFilterInfoType,
    productPurchaseManageFilterEnum: ProductPurchaseManageFilterInfoType,
  ) => {
    return {
      axis: convertToSelectOptions(productFilterEnum.axis),
      loaded: convertToSelectOptions(productFilterEnum.loaded),
      manufacturerCategories: convertManufacturerCategoriesToSelectOptions(
        productFilterEnum.manufacturerCategoriesWithModels,
      ),
      model: convertModelsToSelectOptions(
        productFilterEnum.manufacturerCategoriesWithModels,
        selectedManufacturerCategoryId,
      ),
      productsType: convertToSelectOptions(productFilterEnum.productsType),
      salesType: convertToSelectOptions(productFilterEnum.salesType, [ALL, NORMAL]),
      status: convertToSelectOptions(productFilterEnum.status),
      transmission: convertToSelectOptions(productFilterEnum.transmission),
      locate: convertToSelectOptions(productFilterEnum.locate),
      fuel: convertToSelectOptions(productFilterEnum.fuel),
      color: convertToSelectOptions(productFilterEnum.color),
      salesPeople: convertToSalesPeopleOptions(productFilterEnum.salesPeople),
      productPurchaseManageStatus: convertToSelectOptions(productPurchaseManageFilterEnum.status),
    };
  };

  const [selectedManufacturerCategoryId, setSelectedManufacturerCategoryId] = useState<string>(
    String(data.manufacturerCategories.id),
  );

  const convertModelsOptions = convertModelsToSelectOptions(
    productFilterEnum.manufacturerCategoriesWithModels,
    selectedManufacturerCategoryId,
  );

  const selectOptions = convertProductsFilterInfoToSelectOptions(productFilterEnum, productPurchaseManageFilterEnum);

  const [selectedValue, setSelectedValue] = useState<SelectedValue>({
    seller: data?.sellers ? data.sellers : null,
    truckNumber: data?.truckNumber,
    ownerName: data?.ownerName,
    axis: data?.axis?.code,
    distance: data?.distance,
    tons: data?.tons,
    manufacturerCategories: String(data?.manufacturerCategories?.id),
    model: String(data?.model?.id),
    status: data?.status?.code,
    productsType: data?.type?.code,
    salesType: data?.salesType?.code,
    price: data?.price,
    actualSalePrice: data?.actualSalePrice,
    fuel: data?.fuel?.code,
    transmission: data?.transmission?.code,
    tireStatus: data?.tireStatus?.code,
    garage: data?.garage?.code,
    power: data?.power,
    loaded: data?.loaded?.code,
    loadedInnerLength: data?.loadedInnerLength,
    loadedInnerArea: data?.loadedInnerArea,
    loadedInnerHeight: data?.loadedInnerHeight,
    palletCount: data?.palletCount,
    color: data?.color?.code,
    isAccident: data?.accidentsHistory.accident,
    accidentContents: data?.accidentsHistory.accidentContents,
    transportStartLocate: data?.transportStartLocate?.code,
    transportEndLocate: data?.transportEndLocate?.code,
    transportGoods: data?.transportGoods,
    salesPeople: data?.salesPeople?.id ? String(data?.salesPeople?.id) : null,
    detailContent: data?.detailContent,
    productPurchaseManageStatus: data.productPurchaseManage?.status?.code,
    productPurchaseManageComment: data.productPurchaseManage?.comment,
    prices: data.prices,
    buyerId: data?.buyer?.id ? String(data?.buyer?.id) : null,
    buyer: data.buyer
      ? data.isTheUnbanBuyer
        ? '(더운반) ' + data.buyer.name + '(' + data.buyer.phoneNumber + ')'
        : data.buyer.name + '(' + data.buyer.phoneNumber + ')'
      : '',
  });

  const onChangeInput = (name: string, value: string) => {
    setIsDisabledEditBtn(false);

    if (name === 'manufacturerCategories') {
      setSelectedManufacturerCategoryId(value);
      setSelectedValue((prevOptions) => ({
        ...prevOptions,
        [name]: value,
        model: null,
      }));
    } else if (name === 'distance') {
      const newValueWithoutCommas = value.replace(/,/g, '');

      setSelectedValue((prevOptions) => ({
        ...prevOptions,
        [name]: Number(newValueWithoutCommas),
      }));
    } else {
      setSelectedValue((prevOptions) => ({
        ...prevOptions,
        [name]: value,
      }));
    }
  };

  const { mutate } = useMutation((requestData) => patchProductsDetail(String(data.id), requestData), {
    onSuccess: (response) => {
      message.success('기본 정보가 수정되었습니다.', 2);
      setIsDisabledEditBtn(true);
      if (updateData) {
        updateData(response.data);
      }
    },
    onError: (error: AxiosError) => {
      if (error.code === 'MISSING_REQUEST_PARAMETER') {
        message.error(NO_REQUIRED_VALUE_ERROR_MESSAGE, 2);
      } else if (error.code === 'NOT_MATCH_OWNER_NAME') {
        message.error(NOT_MATCH_OWNER_NAME, 2);
      } else {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      }
    },
  });

  const isValidateChecked = () => {
    const {
      tons,
      distance,
      model,
      garage,
      axis,
      transmission,
      fuel,
      loaded,
      loadedInnerLength,
      loadedInnerArea,
      loadedInnerHeight,
      power,
      color,
      price,
      salesPeople,
      productsType,
      buyerId,
      buyer,
    } = selectedValue;

    const valuesToCheckCarInfo = [
      tons,
      distance,
      model,
      axis,
      transmission,
      fuel,
      loaded,
      loadedInnerLength,
      power,
      price,
      productImagesList[0].value,
      productImagesList[1].value,
      productImagesList[2].value,
    ];

    if (!hasLoadedOnlyLength()) {
      valuesToCheckCarInfo.push(loadedInnerArea, loadedInnerHeight);
    } else {
      const indexLoadedInnerArea = valuesToCheckCarInfo.indexOf(loadedInnerArea);
      const indexLoadedInnerHeight = valuesToCheckCarInfo.indexOf(loadedInnerHeight);

      if (indexLoadedInnerArea !== -1) {
        valuesToCheckCarInfo.splice(indexLoadedInnerArea, 1);
      }

      if (indexLoadedInnerHeight !== -1) {
        valuesToCheckCarInfo.splice(indexLoadedInnerHeight, 1);
      }
    }

    if (productsType === SPEED) {
      valuesToCheckCarInfo.push(salesPeople);
    } else {
      const index = valuesToCheckCarInfo.indexOf(salesPeople);
      if (index !== -1) {
        valuesToCheckCarInfo.splice(index, 1);
      }
    }

    if (
      valuesToCheckCarInfo.some((value) => value == undefined || value == null || value === '' || Number(value) <= 0)
    ) {
      message.error(NO_REQUIRED_VALUE_ERROR_MESSAGE, 2);
      return false;
    } else {
      return true;
    }
  };

  const validateForm = (selectedValue: SelectedValue) => {
    const {
      status,
      price,
      manufacturerCategories,
      model,
      tons,
      loaded,
      loadedInnerArea,
      axis,
      transmission,
      fuel,
      power,
    } = selectedValue;

    if (status === 'SALE') {
      if (!price) {
        error('판매 가격을 입력해주세요.');
        return false;
      } else if (!manufacturerCategories) {
        return false;
      } else if (!model) {
        return false;
      } else if (!tons) {
        return false;
      } else if (!loaded) {
        return false;
      } else if (!loadedInnerArea) {
        return false;
      } else if (!axis) {
        return false;
      } else if (!transmission) {
        return false;
      } else if (!fuel) {
        return false;
      } else if (!power) {
        return false;
      }
    }
    return true;
  };

  const onClickEditBtn = () => {
    if (data?.id) {
      validateForm(selectedValue);
      const request: any = {};
      if (data?.truckNumber !== selectedValue.truckNumber) {
        request.truckNumber = selectedValue.truckNumber;
      }

      if (data?.ownerName !== selectedValue.ownerName) {
        request.ownerName = selectedValue.ownerName;
      }
      if (data?.type?.code !== selectedValue.productsType) {
        request.productsType = selectedValue.productsType;
      }
      if (data?.status?.code !== selectedValue.status) {
        request.productsStatus = selectedValue.status;
      }
      if (data?.price !== Number(selectedValue.price) && selectedValue.price !== null) {
        request.price = selectedValue.price;
      }
      if (data?.distance !== selectedValue.distance) {
        request.distance = selectedValue.distance;
      }
      if (data?.tons !== selectedValue.tons) {
        request.tons = selectedValue.tons;
      }
      if (data?.model?.id !== Number(selectedValue.model)) {
        request.modelId = Number(selectedValue.model);
      }
      if (data?.axis?.code !== selectedValue.axis) {
        request.axis = selectedValue.axis;
      }
      if (data?.transmission?.code !== selectedValue.transmission) {
        request.transmission = selectedValue.transmission;
      }
      if (data?.fuel?.code !== selectedValue.fuel) {
        request.fuel = selectedValue.fuel;
      }
      if (data?.power !== selectedValue.power) {
        request.power = selectedValue.power;
      }
      if (data?.loaded?.code !== selectedValue.loaded) {
        request.loaded = selectedValue.loaded;
      }
      if (data.loadedInnerArea !== selectedValue.loadedInnerArea) {
        request.loadedInnerArea = selectedValue.loadedInnerArea;
      }
      if (data?.loadedInnerHeight !== selectedValue.loadedInnerHeight) {
        request.loadedInnerHeight = selectedValue.loadedInnerHeight;
      }
      if (data?.loadedInnerLength !== selectedValue.loadedInnerLength) {
        request.loadedInnerLength = selectedValue.loadedInnerLength;
      }
      if (data?.palletCount !== Number(selectedValue.palletCount)) {
        request.palletCount = selectedValue.palletCount;
      }
      if (data?.fuel?.code !== selectedValue.fuel) {
        request.fuel = selectedValue.fuel;
      }
      if (data?.color?.code !== selectedValue.color) {
        request.color = selectedValue.color;
      }
      if (data?.garage?.code !== selectedValue.garage) {
        request.garage = selectedValue.garage;
      }
      if (data?.salesType?.code !== selectedValue.salesType) {
        request.salesType = selectedValue.salesType;
      }

      // buyerId가 null로 업데이트가 가능하기 때문에
      // 이전 값을 세팅해주어야 함
      request.buyerId = selectedValue.buyerId;

      if (
        (salesType !== ALL || isSpeed(data.type?.code) || isSpeed(selectedValue.productsType)) &&
        data.salesPeople?.id !== Number(selectedValue.salesPeople)
      ) {
        request.salesPeopleId = selectedValue.salesPeople;
      }
      if (
        (isSpeed(data.type?.code) || isSpeed(selectedValue.productsType)) &&
        data.productPurchaseManage?.status?.code !== selectedValue.productPurchaseManageStatus
      ) {
        request.productPurchaseManageStatus = selectedValue.productPurchaseManageStatus;
      }
      if (
        (isSpeed(data.type?.code) || isSpeed(selectedValue.productsType)) &&
        data.productPurchaseManage?.comment !== selectedValue.productPurchaseManageComment
      ) {
        request.productPurchaseManageComment = selectedValue.productPurchaseManageComment;
      }

      if (
        isEnabledActualSalePriceInput &&
        data?.actualSalePrice !== Number(selectedValue.actualSalePrice) &&
        selectedValue.actualSalePrice !== null
      ) {
        request.actualSalePrice = selectedValue.actualSalePrice;
      }

      // 차량 사진
      if (
        productImageData.length !== productImagesList.length ||
        productImageData.some((item, index) => item.value !== productImagesList[index].value)
      ) {
        request.frontSideImageUrl = productImagesList[0].value;
        request.backSideImageUrl = productImagesList[1].value;
        request.frontImageUrl = productImagesList[2].value;
        request.backImageUrl = productImagesList[3].value;
        request.tireImageUrl = productImagesList[4].value;
        request.engineImageUrl = productImagesList[5].value;
        request.insideImageUrl = productImagesList[6].value;
        request.dashboardImageUrl = productImagesList[7].value;
        request.sheetImageUrl = productImagesList[8].value;
        request.optionImageUrls = [
          productImagesList[9].value,
          productImagesList[10].value,
          productImagesList[11].value,
          productImagesList[12].value,
          productImagesList[13].value,
          productImagesList[14].value,
          productImagesList[15].value,
        ];
      }

      // 차량등록증, 차량 엔진
      if (data?.productsImage?.certificateImageUrl !== certificateData.value) {
        request.certificateImageUrl = certificateData.value;
      }
      if (data?.productsImage?.engineVideoUrl !== engineData.value) {
        request.engineVideoUrl = engineData.value;
      }

      // 매매계약서, 세금계약서
      if (data?.productsImage?.saleInvoiceImageUrl !== saleInvoiceImageUrl.value) {
        request.saleInvoiceImageUrl = saleInvoiceImageUrl.value;
      }

      if (data?.productsImage?.taxImageUrl !== taxImageUrl.value) {
        request.taxImageUrl = taxImageUrl.value;
      }

      if (selectedValue.status !== 'BEFORE_SALE') {
        if (isValidateChecked()) {
          mutate(request);
        }
      } else {
        mutate(request);
      }
    }
  };

  useEffect(() => {
    if (selectedValue.status === 'ORIGIN_DATA_REGISTER') {
      setIsDisabledInput(true);
    }
  }, [selectedValue.status]);

  const isDisabledPurchaseBtn =
    (selectedValue.status !== 'SALE' && selectedValue.status !== 'COMPLETED') || selectedValue.productsType !== SPEED;

  const isEnabledActualSalePriceInput =
    (selectedValue.status === 'SALE' || selectedValue.status === 'COMPLETED') &&
    (selectedValue.salesType === CONSIGNMENT ||
      selectedValue.salesType === ASSURANCE ||
      selectedValue.productsType === SPEED);

  const patchProductPerchaseMutation = useMutation(
    (requestData: { salesType: string }) => patchProductPerchase(String(data.id), requestData),
    {
      onSuccess: ({ data }) => {
        message.success('매입 처리가 완료되었어요.', 2);
        if (data.id) {
          navigate(`/vehicles/assurance/${data.id}`);
        }
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const getMemberByKeywordData = async (keyword: string) => {
    const queryParams = new URLSearchParams({
      keyword: keyword,
    });

    const response = await getMemberByKeyword(queryParams);
    const res = response.data;
    setBuyerList(res);
  };

  const hasLoadedOnlyLength = () => {
    if (
      selectedValue.loaded === 'CARGO' ||
      selectedValue.loaded === 'TANKLORRY' ||
      selectedValue.loaded === 'TRAILER' ||
      selectedValue.loaded === 'LADDER' ||
      selectedValue.loaded === 'AUTOLADDER' ||
      selectedValue.loaded === 'TONGS'
    ) {
      return true;
    } else {
      return false;
    }
  };
  const [updateFlag, setUpdateFlag] = useState(false);
  useEffect(() => {
    setUpdateFlag((prev) => !prev); // 강제로 리렌더링 유도
  }, [buyerList]);
  const postProductInvoicesSendMutation = useMutation(postProductInvoicesForTheunban, {
    onSuccess: () => {
      message.success('더운반에 파일이 전송되었습니다.', 2);
      setIsDisabledProductInvoicesSendBtn(true);
    },
    onError: () => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const onClickProductInvoicesSendBtn = () => {
    if (!data?.productsImage?.saleInvoiceImageUrl) {
      message.error('매매계약서를 업로드해주세요.', 2);
      return;
    }

    if (!data?.productsImage?.saleInvoiceImageUrl) {
      message.error('세금계산서를 업로드해주세요.', 2);
      return;
    }

    postProductInvoicesSendMutation.mutate({
      productId: data.id,
      saleInvoiceImageUrl: data.productsImage.saleInvoiceImageUrl,
      taxImageUrl: data.productsImage.taxImageUrl,
    });
  };

  const handleClear = () => {
    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      buyerId: null,
      buyer: null,
    }));
    setIsDisabledEditBtn(false);
  };

  const downloadAllImageFiles = async () => {
    const optionImageUrls = Array.isArray(data.productsImage.optionImageUrl) ? data.productsImage.optionImageUrl : [];

    const imageUrls = [
      data.productsImage.frontSideImageUrl,
      data.productsImage.backSideImageUrl,
      data.productsImage.frontImageUrl,
      data.productsImage.backImageUrl,
      data.productsImage.tireImageUrl,
      data.productsImage.engineImageUrl,
      data.productsImage.insideImageUrl,
      data.productsImage.dashboardImageUrl,
      data.productsImage.sheetImageUrl,
      ...optionImageUrls,
    ].filter((url) => url && url.trim() !== '');

    if (imageUrls.length === 0) {
      return;
    }

    await Promise.all(
      imageUrls.map(async (url, index) => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Failed to fetch ${url}`);
          }
          const blob = await response.blob();
          const fileName = `image_${index + 1}.${blob.type.split('/')[1] || 'jpg'}`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          throw new Error(`Error`);
        }
      }),
    );
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>판매자</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>{data?.sellers ? data.sellers.name : data.salesPeople?.name}</div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>판매자 번호</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>{data?.sellers?.phoneNumber}</div>
        </Col>
        {(salesType == ASSURANCE || salesType == CONSIGNMENT) && (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>구매자</div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <Select
                  showSearch
                  filterOption={false}
                  className="mr-1"
                  placeholder="이름/전화번호 입력"
                  suffixIcon={null}
                  style={{ width: '100%', minWidth: 250, maxWidth: 128 }}
                  value={selectedValue.buyer || null}
                  onSearch={handleSearch}
                  onChange={handleChange}
                  allowClear
                  onClear={handleClear}
                  options={buyerList.map((buyer) => ({
                    value: buyer.id,
                    label: buyer.isTheunbanMember
                      ? '(더운반) ' + buyer.name + '(' + buyer.phoneNumber + ')'
                      : buyer.name + '(' + buyer.phoneNumber + ')',
                  }))}
                />
              </div>
            </Col>
          </>
        )}
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>제시번호</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>{data?.productsNumber}</div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량명</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>{data?.truckName}</div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량번호</div>
        </Col>
        <Col className="gutter-row" span={5}>
          {salesType === ASSURANCE ? (
            <div style={style}>
              <Input
                className="mr-1"
                placeholder="차량번호 입력"
                style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                value={selectedValue.truckNumber || ''}
                onChange={(e) => onChangeInput('truckNumber', e.target.value)}
              />
            </div>
          ) : (
            <div style={style}>{data?.truckNumber}</div>
          )}
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>소유자명</div>
        </Col>
        <Col className="gutter-row" span={5}>
          {salesType === ASSURANCE ? (
            <div style={style}>
              <Input
                className="mr-1"
                placeholder="소유자명 입력"
                style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                value={selectedValue.ownerName || ''}
                onChange={(e) => onChangeInput('ownerName', e.target.value)}
              />
            </div>
          ) : (
            <div style={style}>{data?.ownerName}</div>
          )}
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {salesType === ALL && (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>매물유형</div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <Select
                  className="mr-4"
                  style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                  placeholder="매물유형"
                  optionFilterProp="children"
                  options={selectOptions.productsType}
                  value={selectedValue.productsType}
                  onChange={(value) => onChangeInput('productsType', value)}
                  disabled={isDisabledInput}
                />
              </div>
            </Col>
          </>
        )}

        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>상태</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="상태"
              optionFilterProp="children"
              options={selectOptions.status}
              value={selectedValue.status}
              onChange={(value) => onChangeInput('status', value)}
              disabled={isDisabledInput}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>주행거리</div>
        </Col>
        <Col className="gutter-row" span={5}>
          {salesType === ALL ? (
            <div style={style}>{formatNumberWithComma(String(data?.distance))}km</div>
          ) : (
            <div style={style}>
              <Input
                className="mr-1"
                placeholder="주행거리 입력"
                style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                value={formatNumberWithComma(String(selectedValue?.distance || ''), true) || ''}
                onChange={(e) => onChangeInput('distance', String(e.target.value))}
              />
              <span className="mr-4">km</span>
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량 색상</div>
        </Col>
        <Col className="gutter-row" span={5}>
          {salesType === ALL ? (
            <div style={style}>{data?.color?.desc}</div>
          ) : (
            <div style={style}>
              <Select
                className="mr-4"
                style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                placeholder="차량 색상"
                optionFilterProp="children"
                options={selectOptions.color}
                value={selectedValue.color}
                onChange={(value) => onChangeInput('color', value)}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>형식</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>{data?.year}</div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>연식</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>{getFormatDate(String(data?.firstRegistrationDate), ['year', 'month'], true)}</div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>톤수</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-1"
              placeholder="톤수 입력"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.tons}
              onChange={(value) => onChangeInput('tons', String(value))}
              disabled={isDisabledInput}
            />
            <span className="mr-4">t</span>
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>제조사</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="제조사"
              optionFilterProp="children"
              options={selectOptions.manufacturerCategories}
              value={selectedValue.manufacturerCategories}
              onChange={(value) => onChangeInput('manufacturerCategories', String(value))}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>모델</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="모델"
              optionFilterProp="children"
              options={convertModelsOptions}
              value={selectedValue.model}
              onChange={(value) => onChangeInput('model', String(value))}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>가변축</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="가변축"
              optionFilterProp="children"
              options={selectOptions.axis}
              value={selectedValue.axis}
              onChange={(value) => onChangeInput('axis', value)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>변속기</div>
        </Col>
        <Col className="gutter-row" span={5}>
          {salesType === ALL ? (
            <div style={style}>{data?.transmission?.desc || '-'}</div>
          ) : (
            <div style={style}>
              <Select
                className="mr-4"
                style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                placeholder="변속기"
                optionFilterProp="children"
                options={selectOptions.transmission}
                value={selectedValue.transmission}
                onChange={(value) => onChangeInput('transmission', value)}
              />
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>연료</div>
        </Col>
        <Col className="gutter-row" span={5}>
          {salesType === ALL ? (
            <div style={style}>{data?.fuel?.desc || '-'}</div>
          ) : (
            <div style={style}>
              <Select
                className="mr-4"
                style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                placeholder="연료"
                optionFilterProp="children"
                options={selectOptions.fuel}
                value={selectedValue.fuel}
                onChange={(value) => onChangeInput('fuel', value)}
              />
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>마력수</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-4"
              placeholder="마력수"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.power}
              onChange={(value) => onChangeInput('power', String(value))}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>적재함 종류</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="적재함 종류"
              optionFilterProp="children"
              options={selectOptions.loaded}
              value={selectedValue.loaded}
              onChange={(value) => onChangeInput('loaded', value)}
            />
          </div>
        </Col>
        {hasLoadedOnlyLength() ? (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>적재함 길이</div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <InputNumber
                  className="mr-1"
                  placeholder="길이"
                  style={{ width: '100%', minWidth: 60, maxWidth: 72 }}
                  value={selectedValue?.loadedInnerLength}
                  onChange={(value) => onChangeInput('loadedInnerLength', String(value))}
                />
                <span className="mr-4">m</span>
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>적재함 길이/너비/높이</div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <InputNumber
                  className="mr-1"
                  placeholder="길이"
                  style={{ width: '100%', minWidth: 60, maxWidth: 72 }}
                  value={selectedValue?.loadedInnerLength}
                  onChange={(value) => onChangeInput('loadedInnerLength', String(value))}
                />
                <InputNumber
                  className="mr-1"
                  placeholder="너비"
                  style={{ width: '100%', minWidth: 60, maxWidth: 72 }}
                  value={selectedValue?.loadedInnerArea}
                  onChange={(value) => onChangeInput('loadedInnerArea', String(value))}
                />
                <InputNumber
                  className="mr-1"
                  placeholder="높이"
                  style={{ width: '100%', minWidth: 60, maxWidth: 72 }}
                  value={selectedValue?.loadedInnerHeight}
                  onChange={(value) => onChangeInput('loadedInnerHeight', String(value))}
                />
                <span className="mr-4">m</span>
              </div>
            </Col>
          </>
        )}
        <>
          <Col className="gutter-row" span={3}>
            <div style={labelStyle}>파렛트</div>
          </Col>
          <Col className="gutter-row" span={5}>
            <div style={style}>
              <InputNumber
                className="mr-1"
                placeholder="갯수"
                style={{ width: '100%', minWidth: 60, maxWidth: 72 }}
                value={selectedValue?.palletCount}
                onChange={(value) => onChangeInput('palletCount', String(value))}
              />

              <span className="mr-4">개</span>
            </div>
          </Col>
        </>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차고지</div>
        </Col>
        <Col className="gutter-row" span={5}>
          {salesType === ALL ? (
            <div style={style}>{data?.garage?.desc}</div>
          ) : (
            <div style={style}>
              <Select
                className="mr-4"
                style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                placeholder="차고지"
                optionFilterProp="children"
                options={selectOptions.locate}
                value={selectedValue.garage}
                onChange={(value) => onChangeInput('garage', value)}
              />
            </div>
          )}
        </Col>

        {salesType === ALL && (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>등록일자</div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>{getOnlyDate(data?.createdDate)}</div>
            </Col>
          </>
        )}
        {salesType === ALL && (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>검사 유효기간</div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                {data?.inspectionInvalidStartDate} ~{data?.inspectionInvalidEndDate}
              </div>
            </Col>
          </>
        )}
        {salesType !== ALL && (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>영업 담당자</div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <Select
                  style={{ width: '100%', minWidth: 86, maxWidth: 200 }}
                  placeholder="영업 담당자"
                  optionFilterProp="children"
                  options={selectOptions.salesPeople}
                  value={selectedValue.salesPeople}
                  onChange={(value) => onChangeInput('salesPeople', String(value))}
                />
              </div>
            </Col>
          </>
        )}
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>판매가격</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-1"
              placeholder="판매 가격 입력"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.price}
              onChange={(value) => onChangeInput('price', String(value))}
              disabled={isDisabledInput}
            />
            <span className="mr-4">만원</span>
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>{isSpeed(selectedValue.productsType || '') ? '실매입금액' : '실판매금액'}</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-1"
              placeholder="매입 가격 입력"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.actualSalePrice}
              onChange={(value) => onChangeInput('actualSalePrice', String(value))}
              disabled={!isEnabledActualSalePriceInput}
            />
            <span className="mr-4">만원</span>
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>시세금액</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <span className="mr-4">
              {selectedValue?.prices != null
                ? formatNumberWithComma(selectedValue?.prices?.price?.toString()) + '만원'
                : '없음'}
            </span>
          </div>
        </Col>

        {(salesType === ASSURANCE || salesType === CONSIGNMENT) && (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>판매유형</div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <Select
                  className="mr-4"
                  style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                  placeholder="판매유형"
                  optionFilterProp="children"
                  options={selectOptions.salesType}
                  value={selectedValue.salesType}
                  onChange={(value) => onChangeInput('salesType', value)}
                />
              </div>
            </Col>
          </>
        )}

        {isSpeed(selectedValue.productsType || '') && (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>관리 담당자</div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <Select
                  className="mr-4"
                  style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                  placeholder="담당자를 선택해주세요"
                  optionFilterProp="children"
                  options={selectOptions.salesPeople}
                  value={selectedValue.salesPeople}
                  onChange={(value) => onChangeInput('salesPeople', value)}
                />
              </div>
            </Col>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>관리상태</div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <Select
                  className="mr-4"
                  style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                  optionFilterProp="children"
                  placeholder="구매관리 상태를 선택해주세요"
                  options={selectOptions.productPurchaseManageStatus}
                  value={selectedValue.productPurchaseManageStatus}
                  onChange={(value) => onChangeInput('productPurchaseManageStatus', value)}
                />
              </div>
            </Col>
          </>
        )}
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>관리메모</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <TextArea
              className="mr-1"
              placeholder="메모입력"
              style={{ width: '100%', minWidth: 100, maxWidth: 300 }}
              value={selectedValue?.productPurchaseManageComment}
              onChange={(e) => onChangeInput('productPurchaseManageComment', e.target.value)}
              disabled={isDisabledInput}
            />
          </div>
        </Col>
        {isSpeed(selectedValue.productsType || '') && (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>매입견적 요청일자</div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>{data?.speedRequestTime}</div>
            </Col>
          </>
        )}
      </Row>

      <Divider></Divider>
      {salesType === ALL && (
        <>
          <div className="flex justify-end">
            <Button
              onClick={onClickPurchase}
              disabled={isDisabledPurchaseBtn || patchProductPerchaseMutation.isLoading}
              loading={patchProductPerchaseMutation.isLoading}
            >
              매입하기
            </Button>
            <Button
              type="primary"
              className="bg-[#1890ff] mx-2 w-20"
              disabled={isDisabledEditBtn}
              onClick={onClickEditBtn}
            >
              수정하기
            </Button>
          </div>
        </>
      )}

      <Row>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량등록증</div>
        </Col>
        <Col className="gutter-row" span={9}>
          <FileUploader
            type="productImage"
            isShowImage={false}
            imageType="CERTIFICATE_IMAGE"
            truckNumber={data?.truckNumber || ''}
            fileData={certificateData}
            setFileData={setCerticicateData}
            fileNameMaxLength={340}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
            isDisabled={salesType === ALL}
          ></FileUploader>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>엔진 영상</div>
        </Col>
        <Col className="gutter-row" span={9}>
          <FileUploader
            type="productImage"
            isShowImage={false}
            imageType="ENGINE_VIDEO"
            truckNumber={data?.truckNumber || ''}
            fileData={engineData}
            setFileData={setEngineData}
            fileNameMaxLength={340}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
            isDisabled={salesType === ALL}
          ></FileUploader>
        </Col>
      </Row>

      <Row>
        <Col className="gutter-row" span={24}>
          <div style={labelStyle}>
            <span className="mr-2">차량 사진</span>
            {salesType !== ALL && (
              <MultiFileUploader
                truckNumber={data?.truckNumber || ''}
                fileNameMaxLength={340}
                setIsDisabledEditBtn={setIsDisabledEditBtn}
                setProductImagesList={setProductImagesList}
              ></MultiFileUploader>
            )}
            <Button icon={<DownloadOutlined />} onClick={downloadAllImageFiles} style={{ marginLeft: 10 }}>
              한번에 다운받기
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="gutter-row" span={24}>
          <ProductImagesPreview
            imagesList={productImagesList}
            setProductImagesList={setProductImagesList}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
            salesType={salesType}
            truckNumber={data?.truckNumber}
            productImageData={productImageData}
          ></ProductImagesPreview>
        </Col>
      </Row>

      <Divider orientation="left"></Divider>
      <Row className="flex ">
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>매매계약서</div>
        </Col>
        <Col className="gutter-row" span={9}>
          <FileUploader
            type="productImage"
            isShowImage={false}
            imageType="SALE_INVOICE_IMAGE"
            truckNumber={data?.truckNumber || ''}
            fileData={saleInvoiceImageUrl}
            setFileData={setSaleInvoiceImageUrl}
            fileNameMaxLength={340}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
            isDisabled={salesType === ALL}
          ></FileUploader>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>세금 계산서</div>
        </Col>
        <Col className="gutter-row" span={9}>
          <FileUploader
            type="productImage"
            isShowImage={false}
            imageType="TAX_IMAGE"
            truckNumber={data?.truckNumber || ''}
            fileData={taxImageUrl}
            setFileData={setTaxImageUrl}
            fileNameMaxLength={340}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
            isDisabled={salesType === ALL}
          ></FileUploader>
        </Col>
      </Row>
      <div className="flex justify-end">
        <Button
          type="primary"
          className="bg-[#1890ff] mx-2 w-40"
          disabled={isDisabledProductInvoicesSendBtn}
          onClick={onClickProductInvoicesSendBtn}
        >
          더운반에 파일 전송하기
        </Button>
      </div>

      {salesType !== ALL && (
        <>
          <Divider orientation="left"></Divider>
          <div className="flex justify-end">
            <Button
              type="primary"
              className="bg-[#1890ff] mx-2 w-20"
              disabled={isDisabledEditBtn}
              onClick={onClickEditBtn}
            >
              수정하기
            </Button>
          </div>
        </>
      )}
      <Modal title="차량 매입" open={isOpenPopup} footer={null} onCancel={handleCloseModal} centered>
        <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '8px', height: '36px', lineHeight: '36px' }}>
            <Button
              style={{
                backgroundColor: '#1890ff', // 파란 배경색
                color: '#fff', // 흰색 글자색
                border: 'none',
                borderRadius: '4px',
                padding: '8px 16px',
                marginRight: '8px',
              }}
              onClick={onClickAssurancePurchaseBtn}
            >
              직트럭 상품용으로 매입하기
            </Button>
          </div>
          <div style={{ marginBottom: '8px', height: '36px', lineHeight: '36px' }}>
            <Button
              style={{
                backgroundColor: '#52c41a', // 초록 배경색
                color: '#fff', // 흰색 글자색
                border: 'none',
                borderRadius: '4px',
                padding: '8px 16px',
                marginRight: '8px',
              }}
              onClick={onClickConsignmentPurchaseBtn}
            >
              판매대행 차량으로 매입하기
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VehicleInfoContent;
