import { ALL } from '@/const/vehicles';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useEffect, useState } from 'react';
import FileUploader from '../../Common/FileUploader';

const DraggableUploadItem = ({
  truckNumber,
  item,
  index,
  setIsDisabledEditBtn,
  productImagesList,
  setProductImagesList,
  salesType,
}: any) => {
  const [file, setFile] = useState(item);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id,
  });

  const imageTypeList = [
    'FRONT_SIDE_IMAGE',
    'BACK_SIDE_IMAGE',
    'FRONT_IMAGE',
    'BACK_IMAGE',
    'TIRE_IMAGE',
    'ENGINE_IMAGE',
    'INSIDE_IMAGE',
    'DASHBOARD_IMAGE',
    'SHEET_IMAGE',
    'OPTION_IMAGE',
    'OPTION_IMAGE',
    'OPTION_IMAGE',
    'OPTION_IMAGE',
    'OPTION_IMAGE',
    'OPTION_IMAGE',
    'OPTION_IMAGE',
  ];

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move',
  };

  useEffect(() => {
    const updatedList = productImagesList.map((image: ProductImage) =>
      image.id === file.id ? { ...image, value: file.value } : image,
    );

    setProductImagesList(updatedList);
  }, [file]);

  return (
    <div
      id={item.id}
      ref={setNodeRef}
      className={`mb-8 ${isDragging ? 'is-dragging' : ''}`}
      style={style}
      {...attributes}
      {...listeners}
    >
      <FileUploader
        type="productImage"
        isShowImage={true}
        imageType={imageTypeList[index]}
        truckNumber={truckNumber}
        fileData={file}
        setFileData={setFile}
        fileNameMaxLength={160}
        setIsDisabledEditBtn={setIsDisabledEditBtn}
        isDisabled={salesType === ALL}
      ></FileUploader>
    </div>
  );
};

export default DraggableUploadItem;
