import React, { useEffect, useState } from 'react';

import { getModels } from '@/api/public';
import ModelList from '@/components/Vehicles/Model/\bModelList';
import { productsEnumAtom } from '@/store/products';
import { Button, Pagination, Select } from 'antd';
import { useAtom } from 'jotai';

const ModelManagement = () => {
  const [productFilterInfo] = useAtom(productsEnumAtom);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedManufacturerCategoryId, setSelectedManufacturerCategoryId] = useState<string | null>(null);
  const [searchResultData, setSearchResultData] = useState<ModelResponse | null>(null);

  const fetchModelData = async (page: number = 1) => {
    const queryParams = new URLSearchParams({
      manufacturerCategoriesId: selectedManufacturerCategoryId || '',
      page: String(page),
      size: '10',
    });
    const response = await getModels(queryParams);
    setSearchResultData(response.data);
  };

  useEffect(() => {
    fetchModelData(currentPage);
  }, [currentPage, selectedManufacturerCategoryId]);

  const resetFilters = () => {
    setSelectedManufacturerCategoryId(null);
    setCurrentPage(1);
  };

  const selectOptions = productFilterInfo.manufacturerCategoriesWithModels.map(
    (item: ManufacturerCategoriesWithModels) => ({
      value: item.manufacturerCategories.id.toString(),
      label: item.manufacturerCategories.name,
    }),
  );

  return (
    <>
      <h2 className="hidden">차량관리 페이지 - 모델 관리</h2>
      <h3 className="text-gray-8">
        차량관리 / <span className="font-semibold">모델 관리</span>
      </h3>
      <div className="py-4">
        <div className="mb-4 flex items-center">
          <Select
            className="mr-4 my-2"
            style={{ width: 200 }}
            placeholder="제조사"
            optionFilterProp="children"
            options={selectOptions}
            value={selectedManufacturerCategoryId}
            onChange={(value) => setSelectedManufacturerCategoryId(value)}
          />
        </div>
        <div className="w-full flex justify-end mb-2">
          <Button onClick={resetFilters}>초기화</Button>
        </div>
      </div>

      <ModelList data={searchResultData} getData={() => fetchModelData(currentPage)} />
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={setCurrentPage}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default ModelManagement;
