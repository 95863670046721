import { Skeleton, Tabs } from 'antd';

import { getProductsDetail } from '@/api/public';
import VehicleDetailContent from '@/components/Vehicles/Common/VehicleDetailContent';
import VehicleHistoryContent from '@/components/Vehicles/Common/VehicleHistoryContent';
import VehicleInfoContent from '@/components/Vehicles/Common/VehicleInfoContent';
import { NORMAL } from '@/const/vehicles';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const DirectDetail = () => {
  const { id } = useParams();
  const productId = id;
  const getProductList = async (productId?: string) => {
    try {
      const response = await getProductsDetail(productId);
      const res = response.data;
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const [data, setData] = useState<ProductDetail>();
  const { isFetching } = useQuery(['get-products'], () => getProductList(productId), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setData(data);
    },
  });

  const updateData = (newData: ProductDetail) => {
    setData(newData);
  };

  let tabs: Tab[] = [
    {
      key: 'info',
      label: '기본 정보',
      children: (
        <Skeleton active loading={isFetching}>
          {data && <VehicleInfoContent salesType={NORMAL} data={data} updateData={updateData} />}
        </Skeleton>
      ),
    },
    {
      key: 'detail',
      label: '상세 정보',
      children: (
        <Skeleton active loading={isFetching}>
          {data && <VehicleDetailContent salesType={NORMAL} data={data} updateData={updateData} />}
        </Skeleton>
      ),
    },
    {
      key: 'history',
      label: '차량 이력',
      children: (
        <Skeleton active loading={isFetching}>
          {data && <VehicleHistoryContent data={data} updateData={updateData} />}
        </Skeleton>
      ),
    },
  ];

  return (
    <>
      <h2 className="hidden">차량관리 페이지 - 전체 차량 - 직거래 차량 상세</h2>
      <h3 className="text-gray-8">
        차량관리 / 전체 차량 /<span className="font-semibold"> 직거래 차량 상세</span>
      </h3>
      <div className="py-4">
        <Tabs defaultActiveKey="info" items={tabs}></Tabs>
      </div>
    </>
  );
};

export default DirectDetail;
