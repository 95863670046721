import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

const { RangePicker } = DatePicker;
interface SearchProps {
  selectedFilterValue: SelectedTermsFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedTermsFilterValue>>;
  getData: () => void;
}

const TermsFilterSelector = ({ selectedFilterValue, setSelectedFilterValue, getData }: SearchProps) => {
  const onChangeSelect = (value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      termsType: value,
    }));
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;

    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      startDate,
      endDate,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      termsType: null,
      startDate: null,
      endDate: null,
      page: null,
      size: null,
    });
  };

  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="약관 타입"
          optionFilterProp="children"
          options={[
            { label: '서비스 이용약관', value: 'USE_TERMS' },
            { label: '개인정보 처리방침', value: 'PERSONAL_INFO_TERMS' },
            { label: '마케팅 정보 수신 동의 약관', value: 'MARKETING' },
          ]}
          value={selectedFilterValue.termsType}
          onChange={(value) => onChangeSelect(value)}
        />
      </div>
      <div className="w-full flex mb-2">
        <div className="w-full">
          <span className="mr-2">시행일자</span>
          <RangePicker
            className="min-w-[400px]"
            value={[
              selectedFilterValue.startDate ? dayjs(selectedFilterValue.startDate) : null,
              selectedFilterValue.endDate ? dayjs(selectedFilterValue.endDate) : null,
            ]}
            onChange={handleDateRangeChange}
            placeholder={['시작 날짜', '종료 날짜']}
          />
        </div>
        <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getData}>
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};

export default TermsFilterSelector;
