import React, { useEffect, useState } from 'react';

import { getLicensesInquiry } from '@/api/public';
import LicenseInquirySearchResult from '@/components/License/Inquiry/LicenseInquirySearchResult';
import LicenseFilterSelector from '@/components/License/LicenseFilterSelector';
import { PURCHASE_INQUIRY } from '@/const/license';
import { Pagination } from 'antd';

const LicenseInquiry = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedLicenseFilterValue>({
    ascending: false,
  });
  const [searchResultData, setSearchResultData] = useState<LicenseInquiryResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const getLicenseInquiryData = async (page?: number) => {
    const {
      memberKeyword,
      locate,
      tons,
      year,
      licenseSalesType,
      licenseType,
      useClassification,
      isCompleted,
      ascending,
    } = selectedFilterValue;
    const queryParams = new URLSearchParams({
      memberKeyword: memberKeyword || '',
      locate: locate || '',
      tons: tons ? String(tons) : '',
      year: year ? String(year) : '',
      licenseSalesType: licenseSalesType || '',
      licenseType: licenseType || '',
      useClassification: useClassification || '',
      isCompleted: isCompleted || '',
      ascending: String(ascending),
      page: String(page ? page : 1),
      size: '10',
    });
    const response = await getLicensesInquiry(queryParams);
    setSearchResultData(response.data);
  };

  useEffect(() => {
    getLicenseInquiryData(currentPage);
  }, [currentPage, selectedFilterValue.ascending]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const updateAscending = () => {
    setSelectedFilterValue({ ...selectedFilterValue, ascending: !selectedFilterValue.ascending });
  };

  return (
    <>
      <h2 className="hidden">번호판 관리 페이지 - 번호판 구매문의</h2>
      <h3 className="text-gray-8">
        번호판 관리 / <span className="font-semibold">번호판 구매문의</span>
      </h3>
      <LicenseFilterSelector
        menuType={PURCHASE_INQUIRY}
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getLicenseData={() => getLicenseInquiryData(currentPage)}
      ></LicenseFilterSelector>
      <LicenseInquirySearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getLicenseData={() => getLicenseInquiryData(currentPage)}
        ascending={selectedFilterValue.ascending}
        updateAscending={updateAscending}
      ></LicenseInquirySearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default LicenseInquiry;
