import { patchPurchaseAccompanyingServices } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { productsEnumAtom } from '@/store/products';
import { purchaseAccompanyingServicesEnumAtom } from '@/store/purchaseAccompanyingServices';
import { convertToSalesPeopleOptions, convertToSelectOptions } from '@/utils/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Select, Table, TableColumnsType, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import BasicPopup from '../Common/BasicPopup';

interface PurchaseAccompanyingServicesResponseDataProps {
  data: PurchaseAccompanyingServicesResponse | null;
  currentPage: number;
  getPurchaseAccompanyingServicesData: () => void;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  name: string;
  phoneNumber: string;
  status: EnumPresenter;
  productId: number;
  productNumber?: number;
  truckName: string;
  loaded?: EnumPresenter;
  loadedInnerLength: number;
  loadedInnerArea?: number;
  loadedInnerHeight?: number;
  transmission?: EnumPresenter;
  year: string;
  price?: number;
  actualSalePrice?: number;
  salesPeople?: SalesPeople;
  comment?: string;
  createdDate?: string;
}

const PurchaseAccompanyingServicesSearchResult = ({
  data,
  currentPage,
  getPurchaseAccompanyingServicesData,
}: PurchaseAccompanyingServicesResponseDataProps) => {
  const [id, setId] = useState<number>(0);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [productFilterEnum] = useAtom(productsEnumAtom);
  const [purchaseAccompanyingServicesFilterInfo] = useAtom(purchaseAccompanyingServicesEnumAtom);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [comment, setCommentContent] = useState('');
  const [basicPopupData, setBasicPopupData] = useState({
    title: '',
    handleOk: () => {},
    handleCancel: () => {},
  });

  const handleStatusChange = (value: string, record: DataType) => {
    const request = {
      id: String(record.id),
      status: value,
    };
    setBasicPopupData({
      title: '상태를 변경하시겠어요?',
      handleOk: () => onClickStatusChangeButton(request),
      handleCancel: () => onClickCancelButton(),
    });
    setIsOpenPopup(true);
  };

  const handleCommentButtonClick = (purchaseAccompanyingService: any) => {
    setIsCommentModalVisible(true);
    setId(purchaseAccompanyingService.id);
    setCommentContent(purchaseAccompanyingService.comment);
  };

  const convertPurchaseAccompanyingServicesFilterInfoSelectOptions = (
    productFilterEnum: ProductsFilterInfoType,
    purchaseAccompanyingServicesFilterInfo: PurchaseAccompanyingServicesFilterInfoType,
  ) => {
    return {
      status: convertToSelectOptions(purchaseAccompanyingServicesFilterInfo.status),
      salesPeople: convertToSalesPeopleOptions(productFilterEnum.salesPeople),
    };
  };

  const selectOptions = convertPurchaseAccompanyingServicesFilterInfoSelectOptions(
    productFilterEnum,
    purchaseAccompanyingServicesFilterInfo,
  );

  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },
    {
      title: '신청자',
      dataIndex: 'name',
    },
    {
      title: '전화번호',
      dataIndex: 'phoneNumber',
    },
    {
      title: '상태',
      dataIndex: 'status',
      render: (text, record) => (
        <Select
          value={record.status?.desc}
          options={selectOptions.status}
          style={{ width: 90 }}
          onChange={(value) => handleStatusChange(value, record)}
        ></Select>
      ),
    },
    {
      title: '제시번호',
      dataIndex: 'productNumber',
    },
    {
      title: '차량명',
      dataIndex: 'truckName',
    },
    {
      title: '특장길이(길이/넓이/높이)',
      dataIndex: 'loadedLength',
      render: (text, record) => (
        <>
          {record.loadedInnerLength}
          {!!record.loadedInnerArea ? ' / ' + record.loadedInnerArea : ''}
          {!!record.loadedInnerHeight ? ' / ' + record.loadedInnerHeight : ''}
        </>
      ),
    },
    {
      title: '변속기',
      dataIndex: 'transmission',
      render: (text, record) => <>{record.transmission ? record.transmission.desc : ''}</>,
    },
    {
      title: '년식',
      dataIndex: 'year',
    },
    {
      title: '거래금액',
      dataIndex: 'price',
      render: (text, record) => (
        <>
          {record.actualSalePrice
            ? !!record.actualSalePrice
              ? record.actualSalePrice + '만원'
              : ''
            : !!record.price
            ? record.price + '만원'
            : ''}
        </>
      ),
    },
    {
      title: '요청일자',
      dataIndex: 'createdDate',
    },
    {
      title: '담당자',
      dataIndex: 'salesPeople',
      render: (text, record) => (
        <Select
          value={record.salesPeople?.name}
          options={selectOptions.salesPeople}
          style={{ width: 100 }}
          onChange={(value) => handleSalesPeopleChange(Number(value), record)}
          onClick={(e) => e.stopPropagation()}
        ></Select>
      ),
    },
    {
      title: '메모',
      dataIndex: 'comment',
      render: (text, record) => (
        <>
          {record.comment ? (
            <>
              <p
                className="font-bold mr-3 overflow-hidden whitespace-no-wrap text-ellipsis break-all"
                style={{
                  maxWidth: '500px',
                  maxHeight: '200px',
                }}
              >
                {record.comment}
              </p>

              <Button onClick={() => handleCommentButtonClick(record)}>수정</Button>
            </>
          ) : (
            <Button onClick={() => handleCommentButtonClick(record)}>작성</Button>
          )}
        </>
      ),
    },
  ];

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      name: item.name,
      phoneNumber: item.phoneNumber,
      status: item.status || null,
      productId: item.product?.id,
      productNumber: item.product?.productsNumber,
      truckName: item.product?.truckName || '',
      loaded: item.product?.loaded,
      loadedInnerLength: item.product?.loadedInnerLength,
      loadedInnerArea: item.product?.loadedInnerArea,
      loadedInnerHeight: item.product?.loadedInnerHeight,
      transmission: item.product?.transmission,
      year: item.product?.year || '',
      price: item.product?.price,
      actualSalePrice: item.product?.actualSalePrice,
      createdDate: item.createdDate || '-',
      comment: item.comment || '',
      salesPeople: item.salesPeople,
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const patchPurchaseAccompanyingServicesModifyMutation = useMutation(
    (requestData: { id: string; status?: string; comment?: string }) =>
      patchPurchaseAccompanyingServices(requestData.id, requestData),
    {
      onSuccess: () => {
        message.success('변경되었습니다.', 2);
        getPurchaseAccompanyingServicesData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
      onSettled: () => {
        setIsOpenPopup(false);
      },
    },
  );

  const onClickCancelButton = () => {
    setIsOpenPopup(false);
  };

  const onClickStatusChangeButton = (requestData: { id: string; status: string }) => {
    patchPurchaseAccompanyingServicesModifyMutation.mutate(requestData);
  };

  const handleCommentModalClose = () => {
    setIsCommentModalVisible(false);
    setCommentContent('');
  };

  const handleCommentSave = () => {
    handleCommentModalClose();
    const request = {
      id: String(id),
      comment: comment,
    };
    patchPurchaseAccompanyingServicesModifyMutation.mutate(request);
  };

  const onChangeComment = (comment: string) => {
    setCommentContent(comment);
  };

  const handleSalesPeopleChange = (value: number, record: DataType) => {
    const request = {
      id: String(record.id),
      salesPeopleId: value,
    };
    setBasicPopupData({
      title: '담당자를 변경하시겠어요?',
      handleOk: () => onClickSalesPeopleChangeButton(request),
      handleCancel: () => onClickCancelButton(),
    });
    setIsOpenPopup(true);
  };

  const onClickSalesPeopleChangeButton = (requestData: { id: string; salesPeopleId: number }) => {
    patchPurchaseAccompanyingServicesSalesPeopleMutation.mutate(requestData);
  };

  const patchPurchaseAccompanyingServicesSalesPeopleMutation = useMutation(
    (requestData: { id: string; salesPeopleId: number }) =>
      patchPurchaseAccompanyingServices(requestData.id, requestData),
    {
      onSuccess: () => {
        message.success('담당자가 변경되었습니다.', 2);
        getPurchaseAccompanyingServicesData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
      onSettled: () => {
        setIsOpenPopup(false);
      },
    },
  );

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 신청건수</span> {data?.totalElements}개
          </div>
        </div>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <BasicPopup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title={basicPopupData.title}
        content=""
        handleOk={() => basicPopupData.handleOk()}
        handleCancel={() => basicPopupData.handleCancel()}
      ></BasicPopup>

      <Modal
        title="메모 보기"
        open={isCommentModalVisible}
        onCancel={handleCommentModalClose}
        footer={[
          <Button key="close" onClick={handleCommentModalClose}>
            닫기
          </Button>,
          <Button
            key="save"
            type="primary"
            className="bg-[#1890ff] mx-2"
            onClick={handleCommentSave}
            disabled={!!!comment}
          >
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={comment}
          onChange={(e) => onChangeComment(e.target.value)}
          placeholder="메모 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>
    </>
  );
};

export default PurchaseAccompanyingServicesSearchResult;
