import { Button, DatePicker, Input, InputNumber, Select } from 'antd';
import { useAtom } from 'jotai';
import React, { useState } from 'react';

import { productPurchaseManageEnumAtom } from '@/store/productPurchaseManage';
import { productsEnumAtom } from '@/store/products';
import { convertToSelectOptions } from '@/utils/common';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Search } = Input;
const { RangePicker } = DatePicker;

interface SearchProps {
  selectedFilterValue: SelectedFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedFilterValue>>;
  getTruckData: () => void;
}

const SpeedFilterSelector = ({ selectedFilterValue, setSelectedFilterValue, getTruckData }: SearchProps) => {
  const [productFilterInfo] = useAtom(productsEnumAtom);
  const [productPurchaseManageFilterEnum] = useAtom(productPurchaseManageEnumAtom);

  const convertProductsFilterInfoToSelectOptions = (
    productsFilterInfo: ProductsFilterInfoType,
    productPurchaseManageFilterEnum: ProductPurchaseManageFilterInfoType,
  ) => {
    return {
      status: convertToSelectOptions(productsFilterInfo.status),

      productPurchaseManageStatus: convertToSelectOptions(productPurchaseManageFilterEnum.status),
      isDelete: [
        { value: null, label: '전체' },
        { value: 'true', label: '삭제' },
        { value: 'false', label: '미삭제' },
      ],
      externalClientsName: [
        { value: null, label: '전체' },
        { value: 'THE_UNBAN', label: '더운반' },
      ],
    };
  };

  const [selectedManufacturerCategoryId, setSelectedManufacturerCategoryId] = useState<string>('');

  const selectOptions = convertProductsFilterInfoToSelectOptions(productFilterInfo, productPurchaseManageFilterEnum);

  const onChangeSelect = (name: string, value: string) => {
    if (name === 'manufacturerCategories') {
      setSelectedManufacturerCategoryId(value);
      setSelectedFilterValue((prevOptions) => ({
        ...prevOptions,
        [name]: value,
        model: null,
      }));
    } else {
      setSelectedFilterValue((prevOptions) => ({
        ...prevOptions,
        [name]: value,
      }));
    }
  };

  const handleInputNumberChange = (name: string, value: number | null) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
      model: null,
    }));
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;

    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      startDate,
      endDate,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      productNumber: null,

      status: null,
      productPurchaseManageStatus: null,

      startDate: null,
      endDate: null,
    });
    sessionStorage.removeItem('speedListParam');
  };

  const handleInputStringChange = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  return (
    <div className="py-4">
      <div className="mb-4">
        <Search
          className="mr-4"
          placeholder="판매자 정보(번호/이름)"
          allowClear
          onSearch={getTruckData}
          style={{ width: 200 }}
          value={selectedFilterValue?.sellerKeyword || ''}
          onChange={(e) => handleInputStringChange('sellerKeyword', e.target.value)}
        />
        <InputNumber
          className="mr-4"
          placeholder="제시번호 입력"
          style={{ width: 180 }}
          value={selectedFilterValue?.productNumber}
          onChange={(value) => handleInputNumberChange('productNumber', value)}
        />
        <Search
          className="mr-4"
          placeholder="차량번호 입력"
          allowClear
          onSearch={getTruckData}
          style={{ width: 180 }}
          value={selectedFilterValue?.truckNumber || ''}
          onChange={(e) => handleInputStringChange('truckNumber', e.target.value)}
        />

        <Select
          className="mr-4"
          style={{ width: 100 }}
          placeholder="매물 상태"
          options={selectOptions.status}
          value={selectedFilterValue.status}
          onChange={(value) => onChangeSelect('status', value)}
        />
        <Select
          className="mr-4"
          style={{ width: 120 }}
          placeholder="구매관리 상태"
          options={selectOptions.productPurchaseManageStatus}
          value={selectedFilterValue.productPurchaseManageStatus}
          onChange={(value) => onChangeSelect('productPurchaseManageStatus', value)}
        />
        <Select
          className="mr-4"
          style={{ width: 100 }}
          placeholder="삭제여부"
          optionFilterProp="children"
          options={selectOptions.isDelete}
          value={selectedFilterValue.isDelete}
          onChange={(value) => onChangeSelect('isDelete', value)}
        />
        <Select
          className="mr-4"
          style={{ width: 200 }}
          placeholder="외부 고객사"
          optionFilterProp="children"
          options={selectOptions.externalClientsName}
          value={selectedFilterValue.externalClientsName}
          onChange={(value) => onChangeSelect('externalClientsName', value)}
        />
      </div>
      <div className="w-full flex mb-2">
        <div className="w-full">
          <span className="mr-2">등록일자</span>
          <RangePicker
            className="min-w-[400px]"
            value={[
              selectedFilterValue.startDate ? dayjs(selectedFilterValue.startDate) : null,
              selectedFilterValue.endDate ? dayjs(selectedFilterValue.endDate) : null,
            ]}
            onChange={handleDateRangeChange}
            placeholder={['시작 날짜', '종료 날짜']}
          />
        </div>
        <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getTruckData}>
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};

export default SpeedFilterSelector;
