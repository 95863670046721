import { Button, Table, TableColumnsType } from 'antd';
import React, { useMemo, useState } from 'react';

import { TableRowSelection } from 'antd/es/table/interface';
import { useNavigate } from 'react-router-dom';

interface TermsResponseDataProps {
  selectedFilterValue: SelectedNoticeFilterValue;
  data: PaginatedResponse<Terms> | null;
  currentPage: number;
  getNoticeData: () => void;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  effectiveDate: string;
  termsType: string;
  version: string;
  contents: string;
  createdDate?: string;
  modifiedDate?: string;
}

const TermsSearchResult = ({ selectedFilterValue, data, currentPage, getNoticeData }: TermsResponseDataProps) => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const rowProps = (record: DataType) => ({
    onClick: () => {
      navigate(`/terms-management/${record.id}`, {
        state: { termsData: data?.data?.filter((item, index) => item.id === record.id)[0] },
      });
    },
  });

  const columns: TableColumnsType<DataType> = useMemo(
    () => [
      {
        title: '번호',
        dataIndex: 'index',
      },
      {
        title: '약관 타입',
        dataIndex: 'termsType',
      },
      {
        title: '내용',
        dataIndex: 'contents',
        render: (text, record) => (
          <>
            <p
              className="mr-3 overflow-hidden whitespace-no-wrap text-ellipsis break-all line-clamp-2"
              style={{
                maxWidth: '500px',
              }}
            >
              {record.contents}
            </p>
          </>
        ),
      },
      {
        title: '버전',
        dataIndex: 'version',
      },
      {
        title: '시행일자',
        dataIndex: 'effectiveDate',
      },
      {
        title: '등록일자',
        dataIndex: 'createdDate',
      },
      {
        title: '수정일자',
        dataIndex: 'modifiedDate',
      },
      {
        title: '복사',
        dataIndex: 'copyButton',
        render: (text, record) => (
          <Button className="mr-2" onClick={(e) => handleClickCopy(e, record)}>
            복사하기
          </Button>
        ),
      },
    ],
    [],
  );

  const handleClickCopy = (e: React.MouseEvent, record: DataType) => {
    e.stopPropagation();
    navigate('/terms-management/form', {
      state: {
        termsData: {
          termsType: record.termsType,
          version: (Number(record.version) + 0.1).toFixed(1),
          effectiveDate: record.effectiveDate,
          contents: record.contents,
        },
      },
    });
  };

  const tableData = useMemo(
    () =>
      data?.data?.map((item, index) => ({
        id: item.id,
        key: item.id,
        index: index + 1,
        contents: item.contents,
        createdDate: item.createdDate,
        effectiveDate: item.effectiveDate,
        modifiedDate: item.modifiedDate,
        termsType: item.termsType.desc,
        version: item.version,
      })) || [],
    [data],
  );

  const onMoveRegisterPage = () => {
    navigate('/terms-management/form');
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 이용약관</span> {data?.totalElements}개
          </div>
          <div>
            <Button className="mr-2" onClick={onMoveRegisterPage} style={{ borderColor: '#1890ff', color: '#1890ff' }}>
              등록
            </Button>
          </div>
        </div>

        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          pagination={false}
          onRow={rowProps}
        />
      </div>
    </>
  );
};

export default TermsSearchResult;
