import { deleteRedisCache } from '@/api/public';
import BasicPopup from '@/components/Common/BasicPopup';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Divider, Select, message } from 'antd';
import React, { useState } from 'react';
import { useMutation } from 'react-query';

const DevelopDataManagement = () => {
  const redisKeyOptions = [
    { value: 'PUBLIC_BANNERS', label: '배너' },
    { value: 'EXTERNAL_PUBLIC_PRICES', label: '시세 - 외부용' },
    { value: 'PUBLIC_PRODUCTS_META_DATA', label: '메타데이터 - 외부용' },
    { value: 'PUBLIC_PRODUCTS_FILTER_INFO', label: '차량 filter info' },
    { value: 'PUBLIC_TERMS', label: '이용약관' },
  ];

  const [isOpenPoppup, setIsOpenPopup] = useState(false);
  const [redisKey, setRedisKey] = useState<string>('PUBLIC_BANNERS');

  const deleteRedisCacheMutation = useMutation(deleteRedisCache, {
    onSuccess: () => {
      message.success('삭제되었습니다.', 2);
      setIsOpenPopup(false);
    },
    onError: () => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const onClickDeleteCacheData = () => {
    deleteRedisCacheMutation.mutate(redisKey);
  };

  const onChangeRedisKeySelect = (value: string) => {
    setRedisKey(value);
  };

  return (
    <>
      <h2 className="hidden">개발 데이터 관리</h2>
      <h3 className="text-gray-8 font-bold">개발 내부 데이터 관리</h3>
      <Divider></Divider>
      <div className="flex pt-4 items-center">
        <span className="mr-4 font-bold">캐시 데이터 삭제</span>
        <Select
          className="mr-4"
          style={{ width: 200 }}
          placeholder="Redis Key Name"
          optionFilterProp="children"
          options={redisKeyOptions}
          value={redisKey}
          onChange={(value) => onChangeRedisKeySelect(value)}
        />
        <Button danger onClick={() => setIsOpenPopup(true)}>
          캐시 데이터 삭제
        </Button>
      </div>
      <BasicPopup
        isOpen={isOpenPoppup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="정말 삭제하시겠어요?"
        content=""
        handleOk={() => onClickDeleteCacheData()}
        handleCancel={() => setIsOpenPopup(false)}
      ></BasicPopup>
    </>
  );
};

export default DevelopDataManagement;
