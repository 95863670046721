import TermsForm from '@/components/Terms/TermsForm';
import { Tabs } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';

const TermsRegisterUpdate = () => {
  const { id } = useParams();

  let tabs: Tab[] = [
    {
      key: 'info',
      label: '이용약관 정보',
      children: <TermsForm />,
    },
  ];
  return (
    <>
      <h3 className="text-gray-8">
        이용약관 관리 /<span className="font-semibold"> 이용약관 {id ? '수정' : '등록'}</span>
      </h3>
      <div className="py-4">
        <Tabs defaultActiveKey="info" items={tabs}></Tabs>
      </div>
    </>
  );
};

export default TermsRegisterUpdate;
